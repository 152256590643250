import { T } from '@tolgee/react'

export const customPartnerTexts = {
  mspark: {
    signInHeader: (
      <T keyName='eleo-partner-texts-mspark-signinheader' params={{ br: <br></br> }}>
        Log in to your AI Assistant
      </T>
    ),
  },
}
