import React, { useContext } from 'react'
import Fallback from '../../../images/user.png'
import { T, useTranslate } from '@tolgee/react'
import { Microbutton } from 'ui'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from 'app/auth'
const LibraryAuthorInfo = ({ description, authorName, avatar }) => {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const authContext = useContext(AuthContext)

  return (
    <div className='bg-[#6363e50d] p-[10px] lg:p-[40px]'>
      <div className='relative'>
        <div className='inline-block w-auto'>
          <Microbutton
            type='main'
            onClick={() => navigate('/chatbot/library')}
            iconPlacement='left'
            icon={
              <ChevronLeftIcon
                className='text-brand-violet-secondary'
                sx={{ height: '18px', width: '18px' }}
              />
            }
            text={t('eleo-chatbot-library-back-to-forum-button', 'Back to chat forum')}
            className='!bg-brand-violet-semi_transparent !px-[8px] !py-[5px]'
            hoverClasses='hover:!bg-brand-violet-semi_transparent/20 transition-all duration-300'
            textClasses='!text-[12px] !font-medium !text-brand-violet-secondary'
          />
        </div>
        <div className='flex items-center justify-center   '>
          <div className='flex flex-col items-center gap-[14px]'>
            <img
              src={avatar || Fallback}
              alt='user'
              className='bg-brand-violet-semi_transparent/20 h-[100px] w-[100px] rounded-full object-cover'
            />
            <div className='flex flex-col items-center gap-[6px]'>
              <div className='flex items-center gap-[3px] text-[14px] font-medium leading-[1.2em]'>
                <span className='text-brand-violet-tertiary'>{authorName}</span>
              </div>
              <p className='text-brand-body mx-auto w-full max-w-[320px]  text-center text-[12px]  font-normal leading-[1.3em]'>
                {description}
              </p>
            </div>
            {authorName === authContext.user?.accounts[0].name && (
              <Microbutton
                variant='transparent-light'
                text={<T keyName='eleo-edit-my-profile'>Edit my profile</T>}
                onClick={() => navigate('/account/profile')}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LibraryAuthorInfo
