import { Animate } from 'components/lib'
import { useState } from 'react'
import { ChatbotLibraryLayout } from 'toolComponents/chat-bot/library'
import CategoriesSwitch from 'toolComponents/chat-bot/library/CategoriesSwitch'
import LibraryBanner from 'toolComponents/chat-bot/library/LibraryBanner'
import SearchResults from 'toolComponents/chat-bot/library/sections/SearchResults'
import { useDebounce } from 'use-debounce'

export const ChatbotLibrary = () => {
  const [filter, setFilter] = useState('')
  const [debounceFilter] = useDebounce(filter, 500)

  return (
    <Animate classOverrides='bg-[#f5f5fa]'>
      <div className='flex flex-col '>
        {!debounceFilter.length ? <LibraryBanner /> : null}

        <CategoriesSwitch filter={filter} setFilter={setFilter} />
        {/* append search UI if input has at least one character */}
        {debounceFilter.length ? (
          <SearchResults
            setFilter={setFilter}
            debounceFilter={debounceFilter}
            isSearchActive={debounceFilter.length}
          />
        ) : (
          <ChatbotLibraryLayout
            filter={debounceFilter}
            searchQuery={filter}
            setFilter={setFilter}
          />
        )}
      </div>
    </Animate>
  )
}

export default ChatbotLibrary
