import { Signup } from 'views/auth/signup/account'
import { APP_NAME } from 'constants'
import { Signin } from 'views/auth/signin'

const Routes = [
  {
    path: 'mspark/signup',
    view: Signup,
    layout: 'auth',
    title: `Sign up to ${APP_NAME}`,
    branding: 'mspark',
  },
  {
    path: 'mspark/signin',
    view: Signin,
    layout: 'auth',
    title: `Welcome to ${APP_NAME}`,
    branding: 'mspark',
  },
]

export default Routes
