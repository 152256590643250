import {
  ChevronRight,
  Delete,
  Edit,
  KeyboardArrowDown,
  MoreVert,
  Save,
  ViewAgendaOutlined,
} from '@mui/icons-material'
import { Popover } from '@mui/material'
import { T, useTranslate } from '@tolgee/react'
import { NestedMenuItem } from 'mui-nested-menu'
import React, { useRef, useState } from 'react'
import { ListPosition, Microbutton, ReactSelect } from 'ui'
import { Modal } from '../../toolComponents/generic/modal'

const DEFAULT_MODAL_DATA = {
  isVisible: false,
  title: '',
  acceptLabel: '',
  type: '',
  context: {},
}

export function TemplatesButton({
  title,
  templates,
  setTemplate,
  handleSaveTemplate,
  handleDeleteTemplate,
  handleUpdateTemplate,
  isLayoutLarge = true,
  lastUsedTemplate,
}) {
  const { t } = useTranslate()
  const mainPopoverRef = useRef()

  const [mainAnchorEl, setMainAnchorEl] = useState(null)
  const [secondaryAnchorEl, setSecondaryAnchorEl] = useState(null)
  const [modalData, setModalData] = useState(DEFAULT_MODAL_DATA)
  const [newTemplateName, setNewTemplateName] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState({ label: '', value: '' })
  const [popoverData, setPopoverData] = useState()

  const handleOpenMainPopover = (event) => {
    setMainAnchorEl(event.currentTarget)
  }
  const handleCloseMainPopover = () => {
    setMainAnchorEl(null)
    setSecondaryAnchorEl(null)
  }
  const handleOpenSecondaryPopover = (event) => {
    setSecondaryAnchorEl(event.currentTarget)
  }
  const handleCloseSecondaryPopover = (e) => {
    setSecondaryAnchorEl(null)

    // Also close the main popover if clicked outside of it
    if (!mainPopoverRef.current) return
    const { top, left, bottom, right } = mainPopoverRef.current.getBoundingClientRect()
    if (e) {
      const { clientX, clientY } = e
      if (!(clientX >= left && clientX <= right && clientY >= top && clientY <= bottom))
        setMainAnchorEl(null)
    }
  }

  const handleCallback = async (type, context) => {
    handleCloseSecondaryPopover()

    switch (type) {
      case 'save':
        await handleSaveTemplate(newTemplateName)
        break
      case 'update':
        await handleUpdateTemplate(context.template.id, {
          templateName: context.template.templateName,
        })
        break
      case 'rename':
        await handleUpdateTemplate(context.template.id, {
          ...context.content,
          templateName: newTemplateName,
        })
        break
      case 'delete':
        await handleDeleteTemplate(context.template.id)
        break
      case 'select-update':
        await handleUpdateTemplate(selectedTemplate.value, {
          templateName: selectedTemplate.label,
        })
        break
      default:
        break
    }
  }

  const isMainPopoverOpen = Boolean(mainAnchorEl)
  const isSecondaryPopoverOpen = Boolean(secondaryAnchorEl)
  const popoverId = isMainPopoverOpen ? 'templates' : undefined

  const modalContent = {
    save: (
      <div className='space-y-[5px] p-[24px]'>
        <p className='body-small text-brand-gray-dark'>
          {t('eleo-templates-modal-subtitle', 'Template name')}
        </p>
        <input
          autoFocus
          spellCheck={false}
          value={newTemplateName}
          onChange={(e) => setNewTemplateName(e.target.value)}
          className='h-[36px] w-full rounded-[4px] border bg-white py-[10px] pl-3 pr-[4px] text-[13px]'
          placeholder={t('eleo-templates-modal-input-placeholder', 'Enter template name')}
        />
      </div>
    ),
    rename: (
      <div className='space-y-[5px] p-[24px]'>
        <p className='body-small text-brand-gray-dark'>
          {t('eleo-templates-modal-subtitle-rename', 'New name')}
        </p>
        <input
          autoFocus
          spellCheck={false}
          value={newTemplateName}
          onChange={(e) => setNewTemplateName(e.target.value)}
          className='h-[36px] w-full rounded-[4px] border bg-white py-[10px] pl-3 pr-[4px] text-[13px]'
        />
      </div>
    ),
    update: (
      <div className='space-y-[5px] p-[24px]'>
        <p className='body-secondary text-brand-violet/80'>
          <T
            keyName='eleo-templates-modal-update-specific'
            params={{ i: <i />, templateName: modalData.context?.template?.templateName }}
            defaultValue='Template <i>{templateName}</i> will be overwritten with the current form content.'
          />
        </p>
      </div>
    ),
    delete: (
      <div className='space-y-[5px] p-[24px]'>
        <p className='body-secondary text-brand-violet/80'>
          <T
            keyName='eleo-templates-modal-content-delete'
            params={{ i: <i />, templateName: modalData.context?.template?.templateName }}
            defaultValue='Template <i>{templateName}</i> will be deleted permamently, this action is irreversible.'
          />
        </p>
      </div>
    ),
    'select-update': (
      <div className='space-y-[5px] p-[24px]'>
        <p className='body-small text-brand-gray-dark'>
          <T keyName='eleo-templates-modal-update-selected' defaultValue='Template to update' />
        </p>
        <ReactSelect
          options={templates.user.map((template) => ({
            value: template.id,
            label: template.templateName,
          }))}
          setSelectedValue={({ value, label }) => setSelectedTemplate({ value, label })}
        />
      </div>
    ),
    load: (
      <div className='space-y-[5px] p-[24px]'>
        {Boolean(templates.user?.length) && (
          <ListPosition text={t('eleo-templates-user-templates', 'User templates')} />
        )}
        <div className='pl-3'>
          {templates.user.map((template) => (
            <ListPosition
              key={template.id}
              height={40}
              type='secondary'
              text={template.templateName}
              onClick={() => {
                setTemplate(template.id)
                handleCloseMainPopover()
                setModalData(DEFAULT_MODAL_DATA)
              }}
            />
          ))}
        </div>
        {/* Predefined templates dont exist yet */}
        {/* <div className='border-brand-form-accent-light mx-[6px] my-2 border-b' />
        <ListPosition text={t('eleo-templates-predefined-templates', 'Predefined templates')} />
        <div className='pl-3'>
          {templates.predefined.map((template) => (
            <ListPosition
              key={template.id}
              height={40}
              type='secondary'
              text={template.templateName}
              onClick={() => {
                setTemplate(template.id)
                handleClose()
                setModalData(DEFAULT_MODAL_DATA)
              }}
            />
          ))}
        </div> */}

        {!templates.user?.length && !templates.predefined?.length && (
          <ListPosition text={t('eleo-templates-no-available', 'No templates available')} />
        )}
      </div>
    ),
  }

  if (isLayoutLarge)
    return (
      <>
        <Microbutton
          text={t('eleo-templates', 'Templates')}
          variant='plain'
          iconPlacement='right'
          icon={<KeyboardArrowDown fontSize='inherit' />}
          className='text-brand-violet-light bg-brand-form-accent-light hover:bg-brand-form-accent hover:text-brand-violet capitalize'
          onClick={handleOpenMainPopover}
        />

        <Popover
          id={popoverId}
          open={isMainPopoverOpen}
          anchorEl={mainAnchorEl}
          onClose={handleCloseMainPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div
            ref={mainPopoverRef}
            className='bg-brand-accent-flat border-brand-form-accent max-h-[500px] w-[216px] overflow-y-scroll rounded-[4px] border p-2 pb-[10px]'
          >
            {Boolean(templates.user?.length) && (
              <ListPosition text={t('eleo-templates-user-templates', 'User templates')} />
            )}
            <div className='pl-3'>
              {templates.user.map((template) => (
                <div key={template.id} className='flex items-center'>
                  <ListPosition
                    text={template.templateName}
                    onClick={() => {
                      setTemplate(template.id)
                      handleCloseMainPopover()
                    }}
                    className='flex-1'
                  />
                  <Microbutton
                    className='size-[30px] bg-transparent'
                    variant='transparent-light'
                    icon={<MoreVert fontSize='inherit' />}
                    onClick={(e) => {
                      setPopoverData(template)
                      handleOpenSecondaryPopover(e)
                    }}
                  />
                </div>
              ))}
              <Popover
                id='secondary-popover'
                open={isSecondaryPopoverOpen}
                anchorEl={secondaryAnchorEl}
                onClose={handleCloseSecondaryPopover}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <div className='bg-brand-accent-flat border-brand-form-accent roudned-[4px] rounded-[4px] border p-2 pb-[10px]'>
                  <ListPosition
                    icon={<Edit fontSize='inherit' />}
                    text={t('eleo-templates-rename', 'Rename')}
                    onClick={() => {
                      handleCloseMainPopover()
                      setNewTemplateName(popoverData.templateName)
                      setModalData({
                        isVisible: true,
                        title: (
                          <T
                            keyName='eleo-templates-modal-title-rename'
                            params={{ i: <i />, templateName: popoverData.templateName }}
                            defaultValue='Rename template <i>{templateName}</i>'
                          />
                        ),
                        acceptLabel: t('eleo-rename', 'rename'),
                        type: 'rename',
                        context: { template: popoverData },
                      })
                    }}
                  />
                  <ListPosition
                    icon={<Save fontSize='inherit' />}
                    text={t('eleo-templates-update', 'Update')}
                    onClick={() =>
                      setModalData({
                        isVisible: true,
                        title: (
                          <T
                            keyName='eleo-templates-modal-title-update-specific'
                            params={{ i: <i />, templateName: popoverData.templateName }}
                            defaultValue='Update template <i>{templateName}</i>'
                          />
                        ),
                        acceptLabel: t('eleo-update', 'update'),
                        type: 'update',
                        context: { template: popoverData },
                      })
                    }
                  />
                  <div className='border-brand-form-accent-light mx-[6px] my-[2px] h-[1px] border-b'></div>
                  <ListPosition
                    icon={<Delete fontSize='inherit' />}
                    text={t('eleo-templates-delete', 'Delete')}
                    onClick={() =>
                      setModalData({
                        isVisible: true,
                        title: (
                          <T
                            keyName='eleo-templates-modal-title-delete'
                            params={{ i: <i />, templateName: popoverData.templateName }}
                            defaultValue='Delete template <i>{templateName}</i>'
                          />
                        ),
                        acceptLabel: t('eleo-delete-permamently', 'delete permamently'),
                        type: 'delete',
                        context: { template: popoverData },
                      })
                    }
                  />
                </div>
              </Popover>
            </div>
            {Boolean(templates.user?.length) && (
              <div className='border-brand-form-accent-light mx-[6px] my-2 border-b' />
            )}

            {/* Predefined templates dont exist yet */}
            {/* 
            <ListPosition text={t('eleo-templates-predefined-templates', 'Predefined templates')} />
            <div className='pl-3'>
              {templates.predefined.map((template) => (
                <ListPosition
                  key={template.id}
                  type='secondary'
                  icon={<ChevronRight fontSize='inherit' />}
                  text={template.templateName}
                  onClick={() => setTemplate(template.id)}
                />
              ))}
            </div> 
            <div className='border-brand-form-accent-light mx-[6px] my-2 border-b' />*/}

            <ListPosition
              text={t('eleo-templates-save-as-new', 'Save as new template')}
              onClick={() => {
                handleCloseMainPopover()
                setNewTemplateName('')

                setModalData({
                  isVisible: true,
                  title: t('eleo-templates-modal-title-save', 'Save as template'),
                  acceptLabel: t('eleo-save', 'save'),
                  type: 'save',
                  context: {},
                })
              }}
            />
          </div>
        </Popover>

        <Modal
          isVisible={modalData.isVisible}
          containerClasses='bg-black bg-opacity-[3%]'
          hideModal={() => setModalData(DEFAULT_MODAL_DATA)}
          title={modalData.title}
          acceptLabel={modalData.acceptLabel}
          content={modalContent[modalData.type]}
          callback={() => handleCallback(modalData.type, modalData.context)}
          isValid={['update', 'delete'].includes(modalData.type) || newTemplateName.length}
        />
      </>
    )

  return (
    <>
      <Microbutton
        icon={<ViewAgendaOutlined fontSize='inherit' />}
        className='size-[40px]'
        variant='transparent'
        onClick={handleOpenMainPopover}
      />

      <Popover
        id={popoverId}
        open={isMainPopoverOpen}
        anchorEl={mainAnchorEl}
        onClose={handleCloseMainPopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className='bg-brand-accent-flat max-h-[500px] w-[216px] p-2 pb-[10px]'>
          <ListPosition text={t('eleo-templates-title', 'templates')} className='uppercase' />
          <div className='border-brand-form-accent-light m-[6px] border-b' />

          {lastUsedTemplate && (
            <>
              <ListPosition text={lastUsedTemplate.templateName} className='' />
              <div className='pl-3'>
                <ListPosition
                  type='secondary'
                  iconPlacement='left'
                  height={40}
                  icon={<Save fontSize='inherit' />}
                  text={t('eleo-templates-update', 'Update')}
                  onClick={() =>
                    setModalData({
                      isVisible: true,
                      title: (
                        <T
                          keyName='eleo-templates-modal-title-update-specific'
                          params={{ i: <i />, templateName: lastUsedTemplate.templateName }}
                          defaultValue='Update template <i>{templateName}</i>'
                        />
                      ),
                      acceptLabel: t('eleo-update', 'update'),
                      type: 'update',
                      context: { template: lastUsedTemplate },
                    })
                  }
                />
                <ListPosition
                  type='secondary'
                  iconPlacement='left'
                  height={40}
                  icon={<Edit fontSize='inherit' />}
                  text={t('eleo-templates-rename', 'Rename')}
                  onClick={() => {
                    handleCloseMainPopover()
                    setNewTemplateName(lastUsedTemplate.templateName)
                    setModalData({
                      isVisible: true,
                      title: (
                        <T
                          keyName='eleo-templates-modal-title-rename'
                          params={{ i: <i />, templateName: lastUsedTemplate.templateName }}
                          defaultValue='Rename template <i>{templateName}</i>'
                        />
                      ),
                      acceptLabel: t('eleo-rename', 'rename'),
                      type: 'rename',
                      context: { template: lastUsedTemplate },
                    })
                  }}
                />

                <ListPosition
                  type='secondary'
                  iconPlacement='left'
                  height={40}
                  icon={<Delete fontSize='inherit' />}
                  text={t('eleo-templates-delete', 'Delete')}
                  onClick={() =>
                    setModalData({
                      isVisible: true,
                      title: (
                        <T
                          keyName='eleo-templates-modal-title-delete'
                          params={{ i: <i />, templateName: lastUsedTemplate.templateName }}
                          defaultValue='Delete template <i>{templateName}</i>'
                        />
                      ),
                      acceptLabel: t('eleo-delete-permamently', 'delete permamently'),
                      type: 'delete',
                      context: { template: lastUsedTemplate },
                    })
                  }
                />
              </div>
              <div className='border-brand-form-accent-light m-[6px] border-b' />
            </>
          )}
          {/* Predefined templates dont exist yet */}
          {/* <div className='border-brand-form-accent-light mx-[6px] my-2 border-b' />
            <ListPosition text={t('eleo-templates-predefined-templates', 'Predefined templates')} />
            <div className='pl-3'>
              {templates.predefined.map((template) => (
                <ListPosition
                  key={template.id}
                  type='secondary'
                  icon={<ChevronRight fontSize='inherit' />}
                  text={template.templateName}
                  onClick={() => setTemplate(template.id)}
                />
              ))}
            </div> */}
          <ListPosition
            height={40}
            text={t('eleo-templates-select-to-update', 'Select template to update')}
            onClick={() => {
              handleCloseMainPopover()
              setSelectedTemplate({ label: '', value: '' })

              setModalData({
                isVisible: true,
                title: t('eleo-templates-modal-title-update', 'Update template'),
                subtitle: t(
                  'eleo-templates-modal-subtitle-update',
                  'Selected template will be overwritten with current form content'
                ),
                acceptLabel: t('eleo-update', 'update'),
                type: 'select-update',
                context: {},
              })
            }}
          />

          <ListPosition
            height={40}
            text={t('eleo-templates-save-as-new', 'Save as new template')}
            onClick={() => {
              handleCloseMainPopover()
              setSelectedTemplate({ label: '', value: '' })

              setModalData({
                isVisible: true,
                title: t('eleo-templates-modal-title-save', 'Save as template'),
                acceptLabel: t('eleo-save', 'save'),
                type: 'save',
                context: {},
              })
            }}
          />

          <div className='border-brand-form-accent-light m-[6px] border-b' />
          <ListPosition
            height={40}
            text={t('eleo-templates-load-template', 'Load template')}
            onClick={() => {
              handleCloseMainPopover()
              setNewTemplateName('')

              setModalData({
                isVisible: true,
                title: t('eleo-templates-modal-load-title', 'Load template'),
                // acceptLabel: t('eleo-save', 'save'),
                type: 'load',
                context: {},
              })
            }}
          />
        </div>
      </Popover>
      <Modal
        isVisible={modalData.isVisible}
        containerClasses='bg-black bg-opacity-[3%]'
        hideModal={() => setModalData(DEFAULT_MODAL_DATA)}
        title={modalData.title}
        acceptLabel={modalData.acceptLabel}
        content={modalContent[modalData.type]}
        callback={() => handleCallback(modalData.type, modalData.context)}
        isValid={
          ['update', 'delete'].includes(modalData.type) ||
          newTemplateName.length ||
          selectedTemplate.value?.length
        }
      />
    </>
  )
}
