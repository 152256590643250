import { isValidUrl } from 'helpers'
import { T } from '@tolgee/react'

export function compareChatbotConfigs(obj1, obj2, keysToSkip = [], currentPath = '') {
  let res = true
  const differences = []

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return [obj1 === obj2, differences]
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return [false, ['array']]
    }
    for (let i = 0; i < obj1.length; i++) {
      const isEqual = compareChatbotConfigs(obj1[i], obj2[i], keysToSkip, `${currentPath}[${i}]`)[0]
      if (!isEqual) return [false, ['array']]
    }
    return [true, differences]
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  const commonKeys = keys1.filter((key) => keys2.includes(key))
  for (let key of commonKeys) {
    const newPath = currentPath ? `${currentPath}.${key}` : key

    // Check if the current path or any parent path is in keysToSkip
    const shouldSkip = keysToSkip.some((skipKey) => {
      // Exact match
      if (skipKey === newPath) return true
      // Parent path match (e.g., 'parent' should match 'parent.child')
      if (newPath.startsWith(skipKey + '.')) return true
      return false
    })

    if (shouldSkip || key === 'author') continue

    const [isEqual, childDifferences] = compareChatbotConfigs(
      obj1[key],
      obj2[key],
      keysToSkip,
      newPath
    )
    if (!isEqual) {
      res = false
      differences.push([newPath, ...childDifferences])
    }
  }

  return [res, differences]
}

export const generateWebsiteCode = (chatSettings, id) => {
  return `
    <!-- Insert below code after body tag -->
    <div 
      id="eleo-chat-bot" 
      position="${chatSettings.chatPlacement}" 
      chat-bot-name="${id}" 
      chat-window-height="${chatSettings.windowHeight}"
      chat-window-width="${chatSettings.windowWidth}"
      widget="true"></div>

    <!-- Insert below code at the end of body tag -->
    <script
      type="text/javascript"
      defer
      src="${renderChatBotURL()}/chat-bot-widget.js">
    </script>`
}

const renderChatBotURL = () => {
  if (process.env.REACT_APP_MODE === 'develop' || process.env.NODE_ENV === 'development') {
    return `https://develop--chat-bot-widget.netlify.app`
  }

  if (process.env.NODE_ENV === 'production') {
    return `https://chatbot.eleo.ai`
  }
}

export const transformLinkToOrigin = (link) => {
  if (link && isValidUrl(link)) {
    const origin = new URL(link).origin
    return origin
  } else {
    return null
  }
}

export const CHATBOT_VISIBILITY_OPTIONS = [
  { label: <T keyName='eleo-chatbot-visibility-public'>Everyone</T>, value: 'public' },
  { label: <T keyName='eleo-chatbot-visibility-private'>Only me</T>, value: 'private' },
  { label: <T keyName='eleo-chatbot-visibility-groups'>Selected groups</T>, value: 'groups' },
]

export const ignoredFieldPerType = {
  forum: [
    'inputValues.monthlyLimit',
    'inputValues.isMonthlyLimit',
    'inputValues.customUrls.public',
    'settings',
  ],
  public: [
    'inputValues.forum',
    'inputValues.relatedGroups',
    'inputValues.visibilityOption',
    'inputValues.customUrls.forum',
    'settings',
  ],
  embed: [
    'settings.telegramBotActive',
    'settings.telegramToken',
    'settings.activeOnEleo',
    'settings.publishing',
    'inputValues.forum',
    'inputValues.relatedGroups',
    'inputValues.visibilityOption',
    'inputValues.customUrls.forum',
    'inputValues.monthlyLimit',
    'inputValues.isMonthlyLimit',
    'inputValues.customUrls.public',
  ],
}
