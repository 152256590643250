import { Close, Search, SmartToy, ThumbUp } from '@mui/icons-material'
import { T } from '@tolgee/react'
import axios from 'axios'
import { useChatbotCategories } from 'components/hooks/chatbotCategories'
import { Animate, AuthContext, useAPI, ViewContext } from 'components/lib'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import CategoriesSwitch from 'toolComponents/chat-bot/library/CategoriesSwitch'
import { ChatbotsCarousel } from 'toolComponents/chat-bot/library/ChatbotsCarousel'
import Divider from 'toolComponents/chat-bot/library/Divider'
import LibraryAuthorInfo from 'toolComponents/chat-bot/library/LibraryAuthorInfo'
import SearchHintsUI from 'toolComponents/chat-bot/library/SearchHintsUI'
import SectionSkeletonLoader from 'toolComponents/chat-bot/library/sections/SectionSkeletonLoader'
import { Microbutton } from 'ui'
import { useDebounce } from 'use-debounce'
import { NotFound } from 'views/error/404'

export const ChatbotLibraryAuthor = () => {
  const context = useContext(ViewContext)
  const authContext = useContext(AuthContext)
  const params = useSearchParams()[0]
  const authorParam = useParams()
  const navigate = useNavigate()

  const [isRecommendationsLoading, setIsRecommendationsLoading] = useState(false)
  const [authorData, setAuthorData] = useState({ description: '' })
  const [isAuthorInvalid, setIsAuthorInvalid] = useState(false)
  const [recommendations, setRecommendations] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [filter, setFilter] = useState('')
  const [author, setAuthor] = useState('')
  const [items, setItems] = useState([])

  const [debounceFilter] = useDebounce(filter, 1000)

  const selectedCategory = params.get('category') || 'eleo-chat-library-all-categories'
  const accountData = useAPI('/api/user/account', 'GET', !Boolean(authorParam?.author))
  const authorAvatar = useAPI(`/api/chat-bot/library/author/avatar/${authorParam?.author}`, 'GET')
  const { chatbotCategories } = useChatbotCategories()

  console.log(chatbotCategories, selectedCategory)

  useEffect(() => {
    if (!authorParam.author) {
      setAuthor(accountData.data?.[0].name)
    } else {
      setAuthor(authorParam.author)
    }
  }, [authorParam, accountData])

  const fetchItems = useCallback(async () => {
    try {
      setIsLoading(true)
      const category = params.get('category')
      const res = await axios.get(
        `/api/chat-bot/library?page=1&pageSize=200&filter=${debounceFilter}&author=${author}${category ? `&category=${category}` : ''}`
      )
      setItems(res.data)
    } catch (err) {
      context.handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [debounceFilter, params, author])

  const fetchRecommendations = useCallback(async () => {
    try {
      setIsRecommendationsLoading(true)
      const category = params.get('category')
      const res = await axios.get(
        `/api/chat-bot/library/author/recommendations/${author}?filter=${debounceFilter}&author=${author}${category ? `&category=${category}` : ''}`
      )
      setRecommendations(res.data.data.chatbots)
      setAuthorData({ description: res.data.data.description })
    } catch (err) {
      if (err.response.data.code === 'AUTHOR_NOT_FOUND') setIsAuthorInvalid(true)
      else context.handleError(err)
    } finally {
      setIsRecommendationsLoading(false)
    }
  }, [debounceFilter, params, author])

  const fetchAll = useCallback(() => {
    fetchItems()
    fetchRecommendations()
  }, [fetchItems, fetchRecommendations])

  const clearSearch = () => {
    setFilter('')
  }

  useEffect(() => {
    if (author) {
      fetchAll()
    }
  }, [fetchAll, author])

  if (isAuthorInvalid) return <NotFound />

  return (
    <Animate classOverrides='bg-[#f5f5fa]'>
      <div className='flex h-full flex-col bg-[#f5f5fa]'>
        <LibraryAuthorInfo
          description={authorData.description}
          authorName={author}
          avatar={authorAvatar?.data?.avatar}
        />
        <CategoriesSwitch filter={filter} setFilter={setFilter} />

        {isLoading && !items.length ? (
          <SectionSkeletonLoader />
        ) : (
          <ChatbotsCarousel
            items={items}
            isSearchActive={filter.length}
            clearSearch={clearSearch}
            searchHintsUI={<SearchHintsUI searchKey={filter} category={selectedCategory} />}
            icon={
              filter.length ? (
                <Search sx={{ height: '18px', width: '18px' }} />
              ) : (
                <SmartToy sx={{ height: '18px', width: '18px' }} />
              )
            }
            title={
              <T
                keyName='eleo-created-by-title'
                defaultValue='Created by {author}'
                params={{ author: author }}
              />
            }
            refetchData={fetchAll}
          />
        )}

        {!items.length && !recommendations.length && !isLoading && !isRecommendationsLoading ? (
          <div className='flex flex-1 items-center justify-center'>
            {debounceFilter.length ? (
              <div className='space-y-2'>
                <div className='text-brand-violet-light text-[15px] leading-[1.5em]'>
                  {selectedCategory !== 'eleo-chat-library-all-categories' ? (
                    <T
                      keyName='eleo-no-results-category'
                      defaultValue='No search results for {filter} in {category}'
                      params={{
                        filter: debounceFilter,
                        category: chatbotCategories.find((cat) => cat.value === selectedCategory)
                          ?.label,
                      }}
                    />
                  ) : (
                    <T
                      keyName='eleo-no-results'
                      defaultValue='No results for {filter}'
                      params={{ filter: debounceFilter }}
                    />
                  )}
                </div>
                <Microbutton
                  variant='transparent-light'
                  text={<T keyName='eleo-clear-search'>Clear search</T>}
                  icon={<Close fontSize='inherit' />}
                  onClick={() => setFilter('')}
                />
              </div>
            ) : (
              <div className='space-y-2'>
                <div className='text-brand-violet-light text-[15px] leading-[1.5em]'>
                  <T keyName='eleo-nothing-to-display'>Nothing to display here...</T>
                </div>
                {author === authContext.user?.accounts[0].name && (
                  <Microbutton
                    variant='transparent-light'
                    text={<T keyName='eleo-create-first-chatbot'>Create your first chatbot</T>}
                    onClick={() => navigate('/chatbot/library/profile?create=1')}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <Divider />
        )}

        {isRecommendationsLoading && !recommendations.length ? (
          <SectionSkeletonLoader />
        ) : (
          <ChatbotsCarousel
            isSearchActive={filter.length}
            clearSearch={clearSearch}
            searchHintsUI={<SearchHintsUI searchKey={filter} category={selectedCategory} />}
            items={recommendations}
            icon={
              filter.length ? (
                <Search sx={{ height: '18px', width: '18px' }} />
              ) : (
                <ThumbUp sx={{ height: '18px', width: '18px' }} />
              )
            }
            title={
              <T
                keyName='eleo-recommended-by-title'
                defaultValue='Recommended by {author}'
                params={{ author: author }}
              />
            }
            refetchData={fetchAll}
          />
        )}
      </div>
    </Animate>
  )
}

export default ChatbotLibraryAuthor
