import { useAccess } from 'components/hooks/access'
import { ToolGeneratorLayoutPrimary, ViewContext } from 'components/lib'
import { useContext } from 'react'
import UpgradePlan from '../UpgradePlan'
import WriterHeader from '../WriterHeader'
import WriterGeneratorActions from './WriterGeneratorActions'
import WriterOptions from './WriterOptions'

export default function WriterGenerator({
  visible,
  dispatch,
  actionTypes,
  checkBoxValues,
  setCheckBoxValues,
  setWordLength,
  setTopic,
  setWriterContext,
  setKeyWords,
  wordLength,
  setWriterLengthOption,
  writerLengthOption,
  setCreativity,
  creativity,
  isOptimizedForSeoEnabled,
  setIsOptimizedForSeoEnabled,
  handleSubmitWriterOptions,
  setModalIsVisible,
  clearInputs,
  optionsData,
  templateOptions,
  setTemplateId,
  loading,
  templateId,
  handleDeleteTemplate,
  isShowAdvanced,
  setIsShowAdvanced,
  contextPlaceHolder,
  topicPlaceHolder,
  isGuruModeEnabled,
  setIsGuruModeEnabled,
  handleClearTemplate,
  isHistoryVisible,
  setIsHistoryVisible,
  documentContext,
  model,
  setModel,
  setPlan,
}) {
  const context = useContext(ViewContext)
  const hasAccess = useAccess()
  const allowStandard = hasAccess('Standard')

  const showUpgradePlanModal = (availableFrom = 'Standard') => {
    context.modal.show({
      children: <UpgradePlan availableFrom={availableFrom} />,
      modalCardClassNames: '!bg-transparent !px-[0px] !py-[0px]',
    })
  }

  const showSaveModal = (availableFrom) => {
    if (allowStandard) {
      setModalIsVisible({ content: 'SAVE_TEMPLATE', visible: true })
    } else {
      showUpgradePlanModal(availableFrom)
    }
  }

  return (
    <ToolGeneratorLayoutPrimary
      topContent={
        <WriterHeader
          historyIsVisible={isHistoryVisible}
          setHistoryIsVisible={setIsHistoryVisible}
          headerTitle='write text'
          toolName='writer-writeText'
        />
      }
      options={
        <WriterOptions
          visible={!isHistoryVisible}
          dispatch={dispatch}
          actionTypes={actionTypes}
          checkBoxValues={checkBoxValues}
          setCheckBoxValues={setCheckBoxValues}
          setWordLength={setWordLength}
          setTopic={setTopic}
          setWriterContext={setWriterContext}
          setKeyWords={setKeyWords}
          wordLength={wordLength}
          setWriterLengthOption={setWriterLengthOption}
          writerLengthOption={writerLengthOption}
          setCreativity={setCreativity}
          creativity={creativity}
          isOptimizedForSeoEnabled={isOptimizedForSeoEnabled}
          setIsOptimizedForSeoEnabled={setIsOptimizedForSeoEnabled}
          optionsData={optionsData}
          templateOptions={templateOptions}
          setTemplateId={setTemplateId}
          showUpgradePlanModal={showUpgradePlanModal}
          templateId={templateId}
          handleDeleteTemplate={handleDeleteTemplate}
          isShowAdvanced={isShowAdvanced}
          setIsShowAdvanced={setIsShowAdvanced}
          contextPlaceHolder={contextPlaceHolder}
          topicPlaceHolder={topicPlaceHolder}
          isGuruModeEnabled={isGuruModeEnabled}
          setIsGuruModeEnabled={setIsGuruModeEnabled}
          handleClearTemplate={handleClearTemplate}
          documentContext={documentContext}
          model={model}
          setModel={setModel}
          setPlan={setPlan}
        />
      }
      actions={
        <WriterGeneratorActions
          showSaveModal={showSaveModal}
          clearInputs={clearInputs}
          handleSubmitWriterOptions={handleSubmitWriterOptions}
          loading={loading}
        />
      }
    />
  )
}
