import { ChevronLeft, ChevronRight, FirstPage, LastPage, MoreVert } from '@mui/icons-material'
import { Popover } from '@mui/material'
import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { Sparkle } from 'phosphor-react'
import { useState } from 'react'
import { ListPosition, Microbutton } from 'ui'

export function Footer({
  currentIndex,
  setCurrentIndex,
  history,
  isGenerating,
  setIsDisplayOutput,
  isLayoutLarge,
  handleApplyForm,
  handleClearOutput,
}) {
  const { t } = useTranslate()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const popoverId = open ? 'save-template' : undefined

  return (
    <div className='bg-brand-grey-bg border-brand-gray-light z-10 -ml-[5px] flex h-[72px] justify-between gap-[8px] border-t p-[16px] lg:gap-[10px]'>
      {!isLayoutLarge && (
        <div className='flex flex-1 items-center gap-2'>
          <Microbutton
            variant='transparent'
            text={t('eleo-form', 'form')}
            icon={<ChevronLeft fontSize='inherit' />}
            className='h-[40px] max-w-[160px] flex-1 uppercase'
            onClick={() => setIsDisplayOutput(false)}
          />
          <Microbutton
            variant='transparent'
            className='size-[40px]'
            icon={<MoreVert fontSize='inherit' />}
            // onClick={() => handleApplyForm(currentIndex)}
            onClick={handleClick}
          />

          <Popover
            id={popoverId}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <div className='bg-brand-accent-flat border-brand-form-accent  rounded-[4px] border p-[10px]'>
              <ListPosition
                text={t(
                  'eleo-apply-form-settings-mobile',
                  'Apply form settings used to generate the images'
                )}
                onClick={() => {
                  handleApplyForm(currentIndex)
                  handleClose()
                }}
              />
              <ListPosition
                text={<T keyName='eleo-image-clear-all'>Clear all</T>}
                onClick={() => {
                  handleClearOutput()
                  handleClose()
                }}
              />
            </div>
          </Popover>
        </div>
      )}

      <div className='flex items-center gap-2'>
        <div
          id='image-navigation'
          className='flex select-none'
          data-tooltip-id='images-imageCreator-imageArrows'
        >
          <Microbutton
            iconSize={24}
            variant='transparent-light'
            className={classNames(
              'size-[40px] rounded-r-none',
              currentIndex <= 0 && '!text-brand-form-accent'
            )}
            disabled={currentIndex <= 0}
            icon={<FirstPage fontSize='inherit' />}
            onClick={() => setCurrentIndex(0)}
          />
          <Microbutton
            iconSize={24}
            variant='transparent-light'
            className={classNames(
              'size-[40px] rounded-none',
              currentIndex <= 0 && '!text-brand-form-accent'
            )}
            disabled={currentIndex <= 0}
            icon={<ChevronLeft fontSize='inherit' />}
            onClick={() => setCurrentIndex((prev) => prev - 1)}
          />
          <div className='bg-brand-form-accent-light flex size-[40px] items-center justify-center'>
            <span
              className={classNames(
                'text-[12px] font-medium',
                history.length === 0 ? 'text-brand-form-accent' : 'text-brand-violet'
              )}
            >
              {currentIndex + 1}/{history.length}
            </span>
          </div>
          <Microbutton
            iconSize={24}
            variant='transparent-light'
            className={classNames(
              'size-[40px] rounded-none',
              currentIndex === history.length - 1 && '!text-brand-form-accent'
            )}
            disabled={currentIndex === history.length - 1}
            icon={<ChevronRight fontSize='inherit' />}
            onClick={() => setCurrentIndex((prev) => prev + 1)}
          />
          <Microbutton
            iconSize={24}
            variant='transparent-light'
            className={classNames(
              'size-[40px] rounded-l-none',
              currentIndex === history.length - 1 && '!text-brand-form-accent'
            )}
            disabled={currentIndex === history.length - 1}
            icon={<LastPage fontSize='inherit' />}
            onClick={() => setCurrentIndex(history.length - 1)}
          />
        </div>
        {isLayoutLarge && (
          <>
            <Microbutton
              variant='transparent'
              className={classNames('h-[40px]', !history.length && '!text-brand-form-accent')}
              text={<T keyName='eleo-image-clear-all'>Clear all</T>}
              onClick={handleClearOutput}
              disabled={history.length === 0}
            />
            <Microbutton
              variant='transparent'
              className={classNames('h-[40px]', !history.length && '!text-brand-form-accent')}
              text={<T keyName='eleo-apply-form-settings'>Apply form settings</T>}
              onClick={() => handleApplyForm(currentIndex)}
              disabled={history.length === 0}
            />
          </>
        )}
      </div>

      {isLayoutLarge && isGenerating && currentIndex !== history.length - 1 && (
        <Microbutton
          variant='transparent-light'
          onClick={() => setCurrentIndex(history.length - 1)}
        >
          <div className='flex items-center !gap-[10px] pl-[14px] pr-[10px] text-[24px]'>
            <Sparkle weight='fill' fontSize={18} />
            <div className='text-[15px] font-medium uppercase leading-[1.2em]'>
              {t('eleo-image-output-generating images', 'Generating images')}
            </div>

            <LastPage fontSize='inherit' />
          </div>
        </Microbutton>
      )}
    </div>
  )
}
