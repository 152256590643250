import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import axios from 'axios'
import classNames from 'classnames'
import { useWindowDimensions } from 'components/hooks/window'
import { ViewContext } from 'components/lib'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import ChatbotsCarouselHeader from './ChatbotsCarouselHeader'
import LibraryItem from './LibraryItem'

export const ChatbotsCarousel = ({
  items,
  title,
  refetchData,
  seeMoreUrl,
  searchHintsUI,
  clearSearch,
  isSearchActive,
  icon,
  disableSlider = false,
}) => {
  const context = useContext(ViewContext)
  const sliderRef = useRef(null)
  const [key, setKey] = useState(new Date())

  const [navigationState, setNavigationState] = useState({ showPrev: false, showNext: true })

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return
    sliderRef.current.swiper.slidePrev()
    sliderRef.current.swiper.slidePrev()
    sliderRef.current.swiper.slidePrev()

    setNavigationState(() => ({ showPrev: !sliderRef.current?.swiper.isBeginning, showNext: true }))
  }, [])

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return
    sliderRef.current.swiper.slideNext()
    sliderRef.current.swiper.slideNext()
    sliderRef.current.swiper.slideNext()

    setNavigationState(() => ({ showPrev: true, showNext: !sliderRef.current?.swiper.isEnd }))
  }, [setNavigationState])

  const handleLikeChatbot = async (id) => {
    try {
      const url = items.find((item) => item.id === id)?.isLiked
        ? '/api/user/liked_chatbots/remove'
        : '/api/user/liked_chatbots/add'
      await axios.post(url, { chatbot_id: id })

      refetchData(new Date())
    } catch (err) {
      context.handleError(err)
    }
  }

  const handleRecommendChatbot = async (id) => {
    try {
      const url = items.find((item) => item.id === id)?.isRecommended
        ? '/api/user/recommended_chatbots/remove'
        : '/api/user/recommended_chatbots/add'
      await axios.post(url, { chatbot_id: id })

      refetchData(new Date())
    } catch (err) {
      context.handleError(err)
    }
  }

  if (!items.length) return

  return (
    <div
      key={key}
      className={classNames(
        'flex h-full flex-col p-[14px] lg:px-[50px] lg:pb-[30px] lg:pt-[25px]',
        disableSlider ? 'gap-[16px]' : 'gap-[6px]'
      )}
    >
      <ChatbotsCarouselHeader
        isSearchActive={isSearchActive}
        icon={icon}
        title={title}
        clearSearch={clearSearch}
        searchHintsUI={searchHintsUI}
      />
      {disableSlider ? (
        <div className='flex  flex-wrap items-center gap-[20px]'>
          {items.map((item) => {
            return (
              <LibraryItem
                key={item.id}
                item={item}
                handleLikeChatbot={handleLikeChatbot}
                handleRecommendChatbot={handleRecommendChatbot}
              />
            )
          })}
        </div>
      ) : (
        <>
          <div className={classNames('slider-container relative py-[10px]')}>
            <Swiper
              ref={sliderRef}
              spaceBetween={10}
              breakpoints={{
                1024: {
                  spaceBetween: 20,
                },
              }}
              slidesPerView={'auto'}
              className='!ml-0 select-none'
              watchOverflow
              onInit={() => setKey(new Date())}
              onTouchEnd={(e) =>
                setNavigationState({ showPrev: !e.isBeginning, showNext: !e.isEnd })
              }
            >
              {items.map((item) => {
                return (
                  <SwiperSlide key={item.id} className='!w-[190px]'>
                    <LibraryItem
                      key={item.id}
                      item={item}
                      handleLikeChatbot={handleLikeChatbot}
                      handleRecommendChatbot={handleRecommendChatbot}
                    />
                  </SwiperSlide>
                )
              })}
            </Swiper>
            {navigationState.showPrev && <SamplePrevArrow onClick={handlePrev} />}
            {navigationState.showNext && sliderRef.current?.swiper.allowSlideNext && (
              <SampleNextArrow onClick={handleNext} />
            )}
          </div>
        </>
      )}

      {/* {seeMoreUrl && (
        <h4 className='flex justify-end'>
          <Link to={seeMoreUrl}>
            <T keyName={'eleo-see-more'}>See more</T>
          </Link>
        </h4>
      )} */}
    </div>
  )
}

function SampleNextArrow(props) {
  const { onClick } = props

  return (
    <div className='next-arrow hidden lg:block'>
      <div
        className='absolute -right-[25px] top-[38%] z-[11] flex  h-[90px] w-[50px] cursor-pointer  items-center justify-center rounded-[25px] bg-white shadow-[0_0_10px_rgba(0,0,0,0.25)]'
        onClick={onClick}
      >
        <button className='flex items-center justify-center'>
          <ChevronRight className='!text-brand-body' sx={{ height: '28px', width: '28px' }} />
        </button>
      </div>
    </div>
  )
}

function SamplePrevArrow(props) {
  const { onClick } = props

  if (props.currentSlide === 0) return
  return (
    <div className='prev-arrow hidden lg:block'>
      <div
        className='absolute -left-[20px] top-[38%] z-[11] flex  h-[90px] w-[50px] cursor-pointer  items-center justify-center rounded-[25px] bg-white shadow-[0_0_10px_rgba(0,0,0,0.25)]'
        onClick={onClick}
      >
        <button className='flex items-center justify-center'>
          <ChevronLeft className='!text-brand-body' sx={{ height: '28px', width: '28px' }} />
        </button>
      </div>
    </div>
  )
}
