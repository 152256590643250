import { ArrowBack, ArrowForward, FiberManualRecord } from '@mui/icons-material'
import classNames from 'classnames'

const ChatPositionSelector = ({ handleDispatchChatSettings, initialPosition = 'bottom-right' }) => {
  const positions = [
    { value: 'top-left', label: <ArrowForward fontSize='inherit' className='rotate-[225deg]' /> },
    { value: 'top-center', label: <ArrowForward fontSize='inherit' className='-rotate-90' /> },
    { value: 'top-right', label: <ArrowForward fontSize='inherit' className='-rotate-45' /> },
    { value: 'middle-left', label: <ArrowForward fontSize='inherit' className='rotate-180' /> },
    { value: 'middle', label: <FiberManualRecord fontSize='small' className='scale-50' /> },
    { value: 'middle-right', label: <ArrowForward fontSize='inherit' /> },
    {
      value: 'bottom-left',
      label: <ArrowForward fontSize='inherit' className='rotate-[135deg]' />,
    },
    { value: 'bottom-center', label: <ArrowForward fontSize='inherit' className='rotate-90' /> },
    { value: 'bottom-right', label: <ArrowForward fontSize='inherit' className='rotate-45' /> },
  ]

  return (
    <div
      className='grid w-full grid-cols-3 grid-rows-3 gap-[5px] rounded-[4px] border p-[10px]'
      style={{ gridTemplateColumns: 'min-content auto min-content' }}
    >
      {positions.map((position, i) => (
        <div key={i} className='flex justify-center'>
          <div
            className={classNames(
              'bg-brand-form-accent-light flex size-[30px] cursor-pointer items-center justify-center rounded-[4px] text-[20px]',
              initialPosition === position.value
                ? 'bg-brand-violet-light text-white'
                : 'text-brand-violet'
            )}
            onClick={() =>
              handleDispatchChatSettings({ field: 'chatPlacement', value: position.value })
            }
          >
            {position.label}
          </div>
        </div>
      ))}
    </div>
  )
}

export default ChatPositionSelector
