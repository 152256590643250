import { Settings } from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { useChatBotVisibilityOptions } from 'components/hooks/useChatBotVisibilityOptions'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const MyChatBotBadge = ({ chatBotVisibilityOption, chatbotPublishing, isDraft, id, item }) => {
  const { visibilityOptionsEnum } = useChatBotVisibilityOptions()
  const { t } = useTranslate()
  const navigate = useNavigate()
  const setParams = useSearchParams()[1]

  const iconStyles = { height: '14px', width: '14px', color: '#ffffff' }

  if (isDraft) {
    return (
      <BadgeWrapper>
        <BadgeUI
          text={t('eleo-chatbot-library-draft', 'draft')}
          bgClasses='bg-brand-body'
          badgeIcon={<Settings sx={iconStyles} />}
        />
      </BadgeWrapper>
    )
  }

  // if (chatBotVisibilityOption === visibilityOptionsEnum.GROUPS) {
  //   return (
  //     <BadgeWrapper isFlex>
  //       <BadgeUI
  //         text={t('eleo-chatbot-library-published-on-forum', 'Published on forum')}
  //         bgClasses='bg-brand-green-secondary hover:bg-brand-green-tertiary transition-all duration-300'
  //         badgeIcon={<Settings sx={iconStyles} />}
  //       />
  //     </BadgeWrapper>
  //   )
  // }

  const getForumBadgeLabel = (visibility) => {
    if (visibility === 'public')
      return t('eleo-chatbot-library-published-forum-public', 'On forum - public')
    if (visibility === 'private')
      return t('eleo-chatbot-library-published-forum-private', 'On forum - me only')
    if (visibility === 'groups')
      return t('eleo-chatbot-library-published-forum-groups', 'On forum - limited')
  }

  const getForumBadgeColor = (visibility) => {
    if (visibility === 'public') return 'bg-[#3cd395]'
    if (visibility === 'private') return 'bg-[#F38F2B]'
    if (visibility === 'groups') return 'bg-[#2CADF2]'
  }

  return (
    <BadgeWrapper isFlex>
      {chatbotPublishing?.forum && (
        <BadgeUI
          text={getForumBadgeLabel(item.visibilityOption)}
          bgClasses={classNames(
            'transition-all hover:brightness-105 duration-300',
            getForumBadgeColor(item.visibilityOption)
          )}
          badgeIcon={<Settings sx={iconStyles} />}
          onClick={() => setParams({ edit: item.id, publishing: 'forum' }, { replace: true })}
        />
      )}

      {(chatbotPublishing?.public || chatBotVisibilityOption === visibilityOptionsEnum.LINK) && (
        <BadgeUI
          text={t('eleo-chatbot-library-published-on-link', 'Published link')}
          bgClasses='bg-brand-violet-secondary hover:bg-brand-violet-tertiary transition-all duration-300'
          badgeIcon={<Settings sx={iconStyles} />}
          onClick={() => setParams({ edit: item.id, publishing: 'link' }, { replace: true })}
        />
      )}
      {!chatbotPublishing?.forum && chatBotVisibilityOption === visibilityOptionsEnum.PRIVATE && (
        <BadgeUI
          text={t('eleo-chatbot-library-published-privately', 'Published privately')}
          bgClasses='bg-brand-violet-secondary hover:bg-brand-violet-tertiary transition-all duration-300'
          badgeIcon={<Settings sx={iconStyles} />}
          onClick={() =>
            navigate(
              `/chatbot/chat/${item.customUrls?.forum?.enableCustomUrl ? item.customUrls?.forum?.customUrlName : item.id}`
            )
          }
        />
      )}
    </BadgeWrapper>
  )
}

export default MyChatBotBadge

const BadgeWrapper = ({ children, isFlex }) => {
  return (
    <div
      className={classNames('absolute top-[10px] z-[11] -ml-[2px]', {
        'flex flex-col gap-[4px]': isFlex,
      })}
    >
      {children}
    </div>
  )
}

const BadgeUI = ({ text, badgeIcon, bgClasses, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'flex min-h-[26px] w-fit max-w-[180px] cursor-pointer items-center gap-[5px] rounded-r-[4px] px-[6px] shadow-[1px_1px_4px_0px_rgba(0,0,0,0.35)]',
        bgClasses
      )}
    >
      {badgeIcon}
      <p className=' whitespace-pre text-wrap text-[12px]  font-medium uppercase leading-[1.2em] text-white'>
        {text}
      </p>
    </div>
  )
}
