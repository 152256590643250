/***
 *
 *   AUTH LAYOUT
 *   Layout for the signup/signin pages
 *
 **********/

import { HomeNav, Footer } from 'components/lib'
import Style from './auth.tailwind.js'

export function AuthLayout(props) {
  return (
    <main className={Style.auth}>
      <HomeNav branding={props.branding} />
      {<props.children {...props.data} branding={props.branding} />}
      <Footer />
    </main>
  )
}
