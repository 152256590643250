import { T, useTranslate } from '@tolgee/react'
import { ContextModal } from 'components/document-context/ContextModal'
import { addContextToFormData } from 'components/document-context/helper'
import { HelpTooltips } from 'components/help/helpTooltips'
import { useAccess } from 'components/hooks/access'
import { useDocumentContext } from 'components/hooks/context'
import { useLanguages } from 'components/hooks/language'
import { useStream } from 'components/hooks/stream'
import { AuthContext, ToolLayoutPrimary, ViewContext } from 'components/lib'
import Tour from 'components/tour'
import {
  convertCreativityToTemperature,
  errorToast,
  languages,
  queryToObj,
  successToast,
} from 'helpers'
import eleoLogo from 'images/eleo_raw.svg'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { WriterAPIService } from 'service/writer-rest-api.service'
import {
  setGeneratedTextHistory,
  setInputHistory,
  writerGeneratedTextHistory,
  writerInitialState,
  writerInputHistory,
} from 'store/toolInputHistoriesHolder/writer'
import { History } from 'ui'
import ActionModal from '../ActionModal'
import UpgradePlan from '../UpgradePlan'
import WriterResult from '../WriterResult'
import { useGetTemplateList } from '../hooks/useGetTemplateList'
import {
  AuthorInputData,
  Creativity,
  FormatInputData,
  ToneInputData,
  WritingStyleInputData,
} from '../writer-options-data'
import { readyMadeTemplates } from '../writer-options-data/ready-made-templates'
import WriterGenerator from './WriterGenerator'

const actionTypes = {
  SET_TEMPLATE: 'SET_TEMPLATE',
  SET_FORMAT: 'SET_FORMAT',
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_STYLE: 'SET_STYLE',
  SET_TONE: 'SET_TONE',
  SET_AUTHOR: 'SET_AUTHOR',
  LOAD_FROM_QUERY: 'LOAD_FROM_QUERY',
  RESET: 'RESET',
}

// Tour steps
const steps = [
  {
    target: '#context-field',
    content: <T keyName='eleo-tour-context'></T>,
  },
  {
    target: '#template',
    content: (
      <T keyName='eleo-tour-writer-1'>
        Use ready-made templates. Quickly write a document using ready-made templates.
      </T>
    ),
  },
  {
    target: '#topic',
    content: (
      <T keyName='eleo-tour-writer-3'>
        Enter the document topic. Write exactly what you want Eleo to write for you.
      </T>
    ),
  },
  {
    target: '.writer-tour-options',
    content: (
      <T keyName='eleo-tour-writer-4'>
        Choose additional options. Set the style, author, tone, and length of the document to get
        the best result.
      </T>
    ),
  },
  {
    target: '#writer-output',
    content: (
      <T keyName='eleo-tour-writer-5'>
        Use the context menu. Quickly change, shorten, or lengthen the selected part of the
        response.
      </T>
    ),
  },
]

function reducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_TEMPLATE:
      return { ...state, template: action.payload }
    case actionTypes.SET_FORMAT:
      return { ...state, format: action.payload }
    case actionTypes.SET_LANGUAGE:
      return { ...state, language: action.payload }
    case actionTypes.SET_STYLE:
      return { ...state, style: action.payload }
    case actionTypes.SET_TONE:
      return { ...state, tone: action.payload }
    case actionTypes.SET_AUTHOR:
      return { ...state, author: action.payload }
    case actionTypes.LOAD_FROM_QUERY:
      const values = action.payload
      let data = {
        format: FormatInputData.find((f) => values.format?.toLowerCase() === f.value.toLowerCase())
          ?.value,
        language: languages.find((l) => values.language?.toLowerCase() === l.value.toLowerCase())
          ?.value,
        style: WritingStyleInputData.find(
          (s) => values.style?.toLowerCase() === s.value.toLowerCase()
        )?.value,
        tone: ToneInputData.find((t) => values.tone?.toLowerCase() === t.value.toLowerCase())
          ?.value,
        author: AuthorInputData.find((a) => values.author?.toLowerCase() === a.value.toLowerCase())
          ?.value,
      }

      data = Object.fromEntries(Object.entries(data).filter(([key, value]) => Boolean(value)))

      return { ...state, ...data }
    case actionTypes.RESET:
      return writerInitialState.history.selectOptionValues
    default:
      return state
  }
}

export default function WriterLayout(props) {
  const { state: completionState, abortController, fetch: fetchCompletion } = useStream()
  const dispatchAction = useDispatch()
  const location = useLocation()
  const writerInputHistoryValues = useSelector(writerInputHistory)
  const writerGeneratedTextHistoryValue = useSelector(writerGeneratedTextHistory)
  const hasAccess = useAccess()
  const documentContext = useDocumentContext('writer')

  const authContext = useContext(AuthContext)
  const context = useContext(ViewContext)
  const [folded, setFolded] = useState(false)
  const [isShowAdvanced, setIsShowAdvanced] = useState(writerInputHistoryValues.showAdvanced)

  const [generatedText, setGeneratedText] = useState(writerGeneratedTextHistoryValue)

  const [topic, setTopic] = useState(writerInputHistoryValues.topic)
  const [writerContext, setWriterContext] = useState(writerInputHistoryValues.writerContext)
  const [plan, setPlan] = useState(writerInputHistoryValues.plan)
  const [keyWords, setKeyWords] = useState(writerInputHistoryValues.keyWords)
  const [checkBoxValues, setCheckBoxValues] = useState(writerInputHistoryValues.checkBoxValues)
  const [wordLength, setWordLength] = useState(writerInputHistoryValues.wordLength)
  const [model, setModel] = useState(writerInputHistoryValues.model)

  const [isOptimizedForSeoEnabled, setIsOptimizedForSeoEnabled] = useState(
    writerInputHistoryValues.isOptimizedForSeoEnabled
  )

  const [isGuruModeEnabled, setIsGuruModeEnabled] = useState(
    writerInputHistoryValues.isGuruModeEnabled
  )

  const [templateId, setTemplateId] = useState(writerInputHistoryValues.templateId)
  const [isPaginateEnd, setIsPaginateEnd] = useState(true)
  const [templateToDelete, setTemplateToDelete] = useState('')
  const [templateName, setTemplateName] = useState('')
  const [loading, setLoading] = useState({ name: '', status: false })
  const [modalIsVisible, setModalIsVisible] = useState({
    content: '',
    visible: false,
  })
  const [writerLengthOption, setWriterLengthOption] = useState(
    writerInputHistoryValues.writerLengthOption
  )
  const [creativity, setCreativity] = useState(writerInputHistoryValues.creativity)
  const [state, dispatch] = useReducer(reducer, writerInputHistoryValues.selectOptionValues)

  const [contextPlaceHolder, setContextPlaceHolder] = useState({
    tolgee_id: '',
    value: '',
  })

  const [topicPlaceHolder, setTopicPlaceHolder] = useState({
    tolgee_id: '',
    value: '',
  })

  const [history, setHistory] = useState([])
  const [isHistoryVisible, setIsHistoryVisible] = useState(false)

  const [checkedHistory, setCheckedHistory] = useState({
    radioBoxName: '',
    checked: false,
  })

  const [timeStamp, setTimeStamp] = useState(Date.now())
  const { defaultToolOutPutLanguage } = useLanguages()

  const [reloadHistoryData, setReloadHistoryData] = useState(0)
  const { templateList, templateOptions, reload, templatesRecordId } = useGetTemplateList(
    'writer_templates',
    '/writer/templates'
  )

  const { t } = useTranslate()

  const optionsData = {
    topic,
    writerContext,
    plan,
    checkBoxValues,
    wordLength,
    isOptimizedForSeoEnabled,
    keyWords,
    isGuruModeEnabled,
    writerLengthOption,
    creativity,
    state,
  }

  const handleSetValuesToInputs = (data, itemToSet, showGeneratedTextValue) => {
    const template = itemToSet.data?.isReadyMade
      ? readyMadeTemplates.find((item) => item.id === itemToSet.value)
      : data.find((item) => item.id === itemToSet.value)

    const checkedCheckboxValues = template?.checkBoxValues?.map((name) => {
      return {
        name: name,
        checked: true,
      }
    })

    if (!template) return

    if (itemToSet.data?.isReadyMade) {
      documentContext.addDocument({
        label: itemToSet.label.toString(),
        type: 'template',
        content: template.value,
        id: template.id,
      })
    }

    if (template.context) {
      const story = documentContext.stories.data.find((story) => story.id === template.context.id)
      documentContext.addDocument({
        label: story.label,
        content: story.content,
        id: story.id,
        type: 'story',
      })
    }

    setTemplateName(template?.templateName)
    setIsOptimizedForSeoEnabled(template.isOptimizedForSeoEnabled)

    if (template?.contextPlaceHolder) {
      setContextPlaceHolder({
        tolgee_id: template.contextPlaceHolder.tolgee_id,
        value: template.contextPlaceHolder.value,
      })
    }

    if (template?.topicPlaceHolder) {
      setTopicPlaceHolder({
        tolgee_id: template.topicPlaceHolder.tolgee_id,
        value: template.topicPlaceHolder.value,
      })
    }

    if (template.wordLength) {
      setWordLength({ id: template.wordLength, checked: true })
    }

    if (template?.isGuruModeEnabled) {
      setIsGuruModeEnabled(template.isGuruModeEnabled)
    }

    if (checkedCheckboxValues?.length) {
      setCheckBoxValues(checkedCheckboxValues)
    }

    if (template.writerLengthOption) {
      setWriterLengthOption(template.writerLengthOption)
    }

    if (template.topic) {
      setTopic(template.topic)
    }

    if (template.writerContext) {
      setWriterContext(template.writerContext)
    }

    if (template.keyWords) {
      setKeyWords(template.keyWords)
    }

    if (template.creativity) {
      setCreativity(template.creativity)
    }

    if (showGeneratedTextValue) {
      setTimeStamp(Date.now())
      setGeneratedText(template.generatedText)
    }

    Object.keys(state).forEach((key) => {
      if (template[key]) {
        dispatch({
          type: actionTypes[`SET_${key.toUpperCase()}`],
          payload: template[key],
        })
      }
    })
  }

  useEffect(() => {
    if (templateId.value) {
      documentContext.removeDocumentsOfType('template')
      handleSetValuesToInputs(templateList, templateId, false)
    }
  }, [templateId, templateList])

  // Load form data from query params
  useEffect(() => {
    const data = queryToObj(location.search)

    dispatch({
      type: 'LOAD_FROM_QUERY',
      payload: data,
    })

    if (data.topic) setTopic(data.topic)
    if (data.context) setWriterContext(data.context)
    if (data.keywords) setKeyWords(data.keywords)
    if (data.creativity)
      setCreativity(
        Creativity.find((c) => data.creativity?.toLowerCase() === c.value.toLowerCase())?.value
      )
    if (data.guru) setIsGuruModeEnabled(true)
    if (data.seo) setIsOptimizedForSeoEnabled(true)
  }, [location])

  const clearInputs = () => {
    documentContext.clearContext()
    setTopic('')
    setWriterContext('')
    setKeyWords('')
    setCheckBoxValues([])
    setIsGuruModeEnabled(false)
    setWordLength({ id: '500', checked: true })
    setIsOptimizedForSeoEnabled(false)
    setWriterLengthOption('words')
    setCreativity('Medium')
    dispatch({ type: actionTypes.RESET })
    setTemplateId({ label: '', value: '' })
    setTemplateName('')
    dispatch({
      type: actionTypes[`SET_LANGUAGE`],
      payload: defaultToolOutPutLanguage.value,
    })
    setTopicPlaceHolder({ tolgee_id: '', value: '' })
    setContextPlaceHolder({ tolgee_id: '', value: '' })
  }

  const handleDeleteTemplate = async (templateId) => {
    setTemplateToDelete(templateId)
    setModalIsVisible({ content: 'DELETE_TEMPLATE', visible: true })
  }

  //function to save/update template or templates group

  const handleTemplate = async () => {
    const data = {
      templatesRecordId,
      accountId: authContext.user.account_id,
      templateId: templateId.value || null,
      writer_templates: {
        templateName: templateName,
        topic,
        writerContext,
        keyWords,
        checkBoxValues: checkBoxValues.map((checkBox) => checkBox.name),
        wordLength: wordLength.id,
        isOptimizedForSeoEnabled,
        isGuruModeEnabled,
        writerLengthOption,
        creativity,
        author: state.author,
        format: state.format,
        language: state.language,
        style: state.style,
        tone: state.tone,
      },
    }

    if (!templateName.length) {
      errorToast(
        t('eleo-save-template-error-please-point-template-name', 'Please point template name')
      )
      return
    }

    // if (templateName && templateName.length < 5) {
    //   errorToast(
    //     t('eleo-save-template-error-name-length', 'Template name should be more than 5 characters')
    //   )
    //   return
    // }

    try {
      setLoading({ name: 'template', status: true })
      const response = templateId.value
        ? await WriterAPIService.updateTemplate(data)
        : await WriterAPIService.saveTemplate(data)
      const responseJSON = await response.json()
      if (responseJSON.success) {
        successToast(t(`eleo-success-${responseJSON.message}`, responseJSON.message))
        setModalIsVisible({ content: '', visible: false })
        setTemplateName('')
        reload()
      } else {
        errorToast(t(`eleo-error-${responseJSON.message}`, responseJSON.message))
      }
    } catch (error) {
      errorToast(error.message)
    } finally {
      setLoading({ name: 'template', status: false })
    }
  }

  useEffect(() => {
    if (!completionState.message) return
    setGeneratedText(completionState.message)
  }, [completionState.message])

  useEffect(() => {
    if (!completionState.command) return
    const cmd = completionState.command
    if (cmd.wordsUsed) {
      context.setWordsLeft((prev) => prev - cmd.wordsUsed)
    }
    if (cmd.error) errorToast(t('eleo-error-generic'))
  }, [completionState.command])

  const handleSubmitWriterOptions = async (loaderName) => {
    setTimeStamp(Date.now())
    setGeneratedText('')
    setLoading({ name: loaderName || 'generate', status: true })

    const kwargs = {
      stream: true,
      temperature: convertCreativityToTemperature(optionsData.creativity),
    }
    let formData = new FormData()
    formData.append('model', model?.value)
    formData.append('tool', 'writeText')
    formData.append('writerOptions', JSON.stringify(optionsData))
    formData.append('kwargs', JSON.stringify(kwargs))
    formData = await addContextToFormData(formData, documentContext.docContext)

    try {
      await fetchCompletion('/api/ai/writer', formData)
      setTimeout(() => setReloadHistoryData(new Date()), 500)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading({ name: loaderName || 'generate', status: false })
    }
  }

  const handleStopRequest = () => {
    if (abortController) {
      // Abort the request by aborting the controller
      abortController.abort()
    }
  }

  const handleClearTemplate = () => {
    setTopicPlaceHolder({ tolgee_id: '', value: '' })
    setContextPlaceHolder({ tolgee_id: '', value: '' })
  }

  //save input values into history
  useEffect(() => {
    dispatchAction(
      setInputHistory({
        topic,
        writerContext,
        writerLengthOption,
        keyWords,
        checkBoxValues,
        wordLength,
        isOptimizedForSeoEnabled,
        creativity,
        isGuruModeEnabled,
        selectOptionValues: state,
        templateId,
        showAdvanced: isShowAdvanced,
        model,
      })
    )
    dispatchAction(setGeneratedTextHistory(generatedText))
  }, [
    topic,
    writerContext,
    writerLengthOption,
    keyWords,
    checkBoxValues,
    wordLength,
    isOptimizedForSeoEnabled,
    creativity,
    loading,
    state,
    templateId,
    isShowAdvanced,
    isGuruModeEnabled,
    dispatchAction,
    model,
  ])

  // Fetch history
  useEffect(() => {
    const getHistory = async () => {
      const pages = history.length > 0 ? Math.ceil(history.length / 20) : 1
      const response = await WriterAPIService.getHistories({
        pages: pages,
      })
      const { data } = await response.json()

      const historyData = data?.history.map((item) => ({
        id: item.id,
        question: item.writer_template?.topic,
        answer: item.generated_response,
        date_created: item.date_created,
        context: item.writer_template?.context,
        words: item.writer_template?.wordsUsed,
        data: item,
      }))
      setHistory(historyData ?? [])
    }

    getHistory()
  }, [reloadHistoryData])

  async function handleLoadItem(id) {
    if (!hasAccess('Standard')) {
      context.modal.show({
        children: <UpgradePlan availableFrom='Standard' />,
        modalCardClassNames: '!bg-transparent !px-[0px] !py-[0px]',
      })
      return
    }
    const historyItem = history.find((item) => item.id === id)?.data

    const template = {
      myText: historyItem.writer_template.myText,
      whatToDo: historyItem.writer_template.whatToDo,
      action: historyItem.writer_template.action,
      checkBoxValues: historyItem.writer_template.checkBoxValues,
      author: historyItem.writer_template.author,
      creativity: historyItem.writer_template.creativity,
      format: historyItem.writer_template.format,
      isOptimizedForSeoEnabled: historyItem.writer_template.isOptimizedForSeoEnabled,
      keyWords: historyItem.writer_template.keyWords,
      isGuruModeEnabled: historyItem.writer_template.isGuruModeEnabled,
      language: historyItem.writer_template.language,
      style: historyItem.writer_template.style,
      templateName: '',
      tone: historyItem.writer_template.tone,
      topic: historyItem.writer_template.topic,
      wordLength: historyItem.writer_template.wordLength,
      writerContext: historyItem.writer_template.writerContext,
      writerLengthOption: historyItem.writer_template.writerLengthOption,
      generatedText: historyItem.generated_response,
      context: historyItem.writer_template?.context?.isStory
        ? historyItem.writer_template.context
        : null,
    }

    handleSetValuesToInputs([template], checkedHistory.radioBoxName, true)
    setIsHistoryVisible(false)
  }

  async function handleDeleteHistoryItems(items) {
    setModalIsVisible({ content: 'DELETE_HISTORY', visible: true, items: items })
  }

  async function handleClearHistory() {
    setModalIsVisible({ content: 'CLEAR_HISTORY', visible: true })
  }

  useEffect(() => {
    if (history.length && history.length % 20 === 0) setIsPaginateEnd(false)
    else setIsPaginateEnd(true)
  }, [history.length])

  async function handlePaginate() {
    const currentPage = Math.ceil(history.length / 20)
    const res = await WriterAPIService.getHistories({
      skip: currentPage,
    })
    const { data } = await res.json()

    const historyData = data?.history.map((item) => ({
      id: item.id,
      question: item.writer_template?.topic,
      answer: item.generated_response,
      date_created: item.date_created,
      context: item.writer_template?.context,
      words: item.writer_template?.wordsUsed,
      data: item,
    }))
    setHistory((prev) => [...prev, ...(historyData ?? [])])
  }

  return (
    <>
      <ToolLayoutPrimary
        folded={folded}
        setFolded={setFolded}
        storageKey='writer'
        leftSideContent={
          isHistoryVisible ? (
            <History
              headerText={t('eleo-history-header-writer', 'writer / history')}
              dateText={t('eleo-history-item-date', 'Started:')}
              storyText={t('eleo-history-item-story', 'Your story:')}
              wordCounterText={t('eleo-history-item-words', 'words:')}
              historyEmptyText={t('eleo-tool-history-is-empty', 'history is empty')}
              clearHistoryText={t('eleo-history-header-clear', 'Clear History')}
              storyMissingText={t('eleo-history-item-story-missing', 'None')}
              logo={eleoLogo}
              storageKey='writer'
              items={history}
              closeHistory={() => setIsHistoryVisible(false)}
              loadItem={handleLoadItem}
              removeItems={handleDeleteHistoryItems}
              clearHistory={handleClearHistory}
              fetchMoreItems={handlePaginate}
              hasMore={!isPaginateEnd}
            />
          ) : (
            <WriterGenerator
              isHistoryVisible={isHistoryVisible}
              setIsHistoryVisible={setIsHistoryVisible}
              dispatch={dispatch}
              actionTypes={actionTypes}
              checkBoxValues={checkBoxValues}
              setCheckBoxValues={setCheckBoxValues}
              setWordLength={setWordLength}
              setTopic={setTopic}
              setWriterContext={setWriterContext}
              setPlan={setPlan}
              setKeyWords={setKeyWords}
              wordLength={wordLength}
              setWriterLengthOption={setWriterLengthOption}
              writerLengthOption={writerLengthOption}
              setCreativity={setCreativity}
              creativity={creativity}
              isOptimizedForSeoEnabled={isOptimizedForSeoEnabled}
              setIsOptimizedForSeoEnabled={setIsOptimizedForSeoEnabled}
              handleSubmitWriterOptions={handleSubmitWriterOptions}
              setModalIsVisible={setModalIsVisible}
              clearInputs={clearInputs}
              optionsData={optionsData}
              templateOptions={templateOptions}
              setTemplateId={setTemplateId}
              loading={loading}
              history={history}
              setHistory={setHistory}
              checkedHistory={checkedHistory}
              setCheckedHistory={setCheckedHistory}
              reloadHistoryData={reloadHistoryData}
              handleSetValuesToInputs={handleSetValuesToInputs}
              templateId={templateId}
              handleDeleteTemplate={handleDeleteTemplate}
              isShowAdvanced={isShowAdvanced}
              setIsShowAdvanced={setIsShowAdvanced}
              contextPlaceHolder={contextPlaceHolder}
              topicPlaceHolder={topicPlaceHolder}
              isGuruModeEnabled={isGuruModeEnabled}
              setIsGuruModeEnabled={setIsGuruModeEnabled}
              handleClearTemplate={handleClearTemplate}
              documentContext={documentContext}
              model={model}
              setModel={setModel}
            />
          )
        }
        rightSideContent={
          <WriterResult
            generatedText={generatedText}
            setGeneratedText={setGeneratedText}
            regenerate={() => handleSubmitWriterOptions('regenerate')}
            folded={folded}
            loading={loading}
            toolName='writer-writeText'
            handleSaveGeneratedTextHistory={(text) => dispatchAction(setGeneratedTextHistory(text))}
            handleStopRequest={handleStopRequest}
            selectedLanguage={state.language}
            topic={topic}
            key={timeStamp}
          />
        }
      >
        {/* modalIsVisible.content */}
        <>
          {modalIsVisible.visible && (
            <ActionModal
              modalKey={modalIsVisible.content}
              items={modalIsVisible.items}
              templateId={templateId}
              templateName={templateName}
              setTemplateName={setTemplateName}
              handleTemplate={handleTemplate}
              loading={loading}
              setModalIsVisible={setModalIsVisible}
              setReloadHistoryData={setReloadHistoryData}
              setCheckedHistory={setCheckedHistory}
              checkedHistory={checkedHistory}
              clearHistoryEndpoint={() => WriterAPIService.clearHistory()}
              deleteSelectedHistoryEndpoint={(data) => WriterAPIService.deleteHistory(data)}
              reloadTemplatesData={reload}
              deleteTemplateEndpoint={() =>
                WriterAPIService.deleteTemplate({ templateId: templateToDelete })
              }
            />
          )}
        </>
        <ContextModal documentContext={documentContext} />
        <HelpTooltips group='writer' tool='writeText' />
        <Tour steps={steps} name='writer' />
      </ToolLayoutPrimary>
    </>
  )
}
