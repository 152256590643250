import { T, useTranslate } from '@tolgee/react'
import {
  Animate,
  AuthContext,
  Button,
  Form,
  Message,
  SocialSignin,
  useNavigate,
} from 'components/lib'
import { APP_NAME } from 'constants'
import { errorToast } from 'helpers'
import { useContext, useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { errorCodes } from 'components/view/errorCodes'
import { customPartnerTexts } from 'partners/customTexts'

export function Signin(props) {
  // context
  const authContext = useContext(AuthContext)
  const { t } = useTranslate()
  const [params, setParams] = useSearchParams()
  const navigate = useNavigate()

  const next = params.get('next')

  // state
  const [useMagic, setUseMagic] = useState(false)
  const [magicLinkSent, setMagicLinkSent] = useState(false)
  const [form, setForm] = useState({
    email: {
      label: <T keyName='eleo-login-email-label'>Email</T>,
      type: 'email',
      required: true,
      class:
        'block w-full rounded-md border-0 py-1.5 text-brand-body shadow-sm ring-1 ring-inset ring-brand-grey placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-violet sm:text-sm sm:leading-6',
    },
    password: {
      label: <T keyName='eleo-login-password-label'>Password</T>,
      type: 'password',
      required: true,
      class:
        'block w-full rounded-md border-0 py-1.5 text-brand-body shadow-sm ring-1 ring-inset ring-brand-grey placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-violet sm:text-sm sm:leading-6',
    },
    forgotpassword: {
      type: 'link',
      url: '/forgotpassword',
      text: <T keyName='eleo-login-forgot-your-password-label'>Forgot your password?</T>,
      class: 'font-semibold text-brand-violet hover:text-indigo-500 text-sm leading-6',
    },
  })

  useEffect(() => {
    // was an error message passed from the server router?
    const err = params.get('error')
    if (err) {
      const msg = decodeURIComponent(err)
      if (errorCodes[msg]) errorToast(errorCodes[msg], null, 10000)
      else errorToast(msg, null, 10000)

      if (msg === 'USE_SIGNUP_PAGE') return navigate('/signup')

      params.delete('error')
      setParams(params)
    }
  }, [params, setParams, t, navigate])

  function toggleMagicLink(useMagic) {
    const f = { ...form }
    f.password.type = useMagic ? 'hidden' : 'password'
    f.password.required = useMagic ? false : true
    f.forgotpassword.type = useMagic ? 'hidden' : 'link'

    setForm(f)
    setUseMagic(useMagic)
  }

  return (
    <Animate type='pop'>
      <div className='flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          {/* <img
          className="mx-auto h-10 w-auto"
          src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
          alt="Your Company"
        /> */}
          <h2 className=' mt-6 text-center text-[26px] font-bold leading-[1.3em] tracking-tight'>
            {props.branding ? (
              customPartnerTexts[props.branding]?.signInHeader
            ) : (
              <>
                <T keyName='eleo-login-header'>Log in to</T> {APP_NAME}
              </>
            )}
          </h2>
        </div>

        {magicLinkSent ? (
          <div style={{ width: '28em', margin: '0 auto', marginTop: 50 }}>
            <Message
              title={<T keyName='eleo-magic-link-sent'>Check Your Email</T>}
              type='success'
              text={
                <T keyName='eleo-magic-link-sent-description'>
                  Please click the link in your email inbox to log in.
                </T>
              }
            />
          </div>
        ) : (
          <>
            <div className='my-[20px]'>
              {useMagic ? (
                <Button
                  text={<T keyName='eleo-use-password'>Use password</T>}
                  color='purple'
                  icon='shield'
                  iconColor='white'
                  className='hover:bg-brand-violet-light mx-auto mb-5'
                  rounded
                  iconButton
                  small
                  action={() => {
                    toggleMagicLink(false)
                  }}
                />
              ) : (
                <Button
                  text={<T keyName='eleo-use-magic-link-instead'>Use magic link instead</T>}
                  color='purple'
                  icon='star'
                  iconColor='white'
                  className='hover:bg-brand-violet-light mx-auto mb-5'
                  rounded
                  iconButton
                  small
                  action={() => {
                    toggleMagicLink(true)
                  }}
                />
              )}
            </div>

            <div className='sm:mx-auto sm:w-full sm:max-w-[480px]'>
              <div className='bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12'>
                <Form
                  inputs={form}
                  inputLabelClass='block text-sm font-medium leading-6 text-brand-body capitalize'
                  method='POST'
                  btnColor='purple'
                  formLinkClasses='font-semibold text-brand-violet hover:text-indigo-500 text-sm leading-6 mt-[24px]'
                  btnClasses='flex w-full justify-center rounded-md bg-brand-violet px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-brand-violet-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  url={useMagic ? '/api/auth/magic' : '/api/auth'}
                  buttonText={
                    useMagic ? (
                      <T keyName='eleo-send-magic-link-button'>Send Magic Link</T>
                    ) : (
                      <T keyName='eleo-header-log-in-button'>Log In</T>
                    )
                  }
                  callback={(res) => {
                    useMagic
                      ? setMagicLinkSent(true)
                      : res.data['2fa_required']
                        ? navigate(`/signin/otp?token=${res.data.token}`)
                        : navigate(authContext.signin(res, next))
                  }}
                />
                <div>
                  <div className='relative mt-10'>
                    <div className='absolute inset-0 flex items-center' aria-hidden='true'>
                      <div className='w-full border-t border-gray-200' />
                    </div>
                    <div className='relative flex justify-center text-sm font-medium leading-6'>
                      <span className='text-brand-violet bg-white px-6 '>
                        <T keyName='eleo-or-continue-with-button'>Or continue with</T>
                      </span>
                    </div>
                  </div>
                  <div className='mt-6'>
                    <SocialSignin
                      // network={['twitter', 'google']}
                      network={['facebook', 'twitter', 'google']}
                      useMagic={useMagic}
                      showOr={false}
                      next={next}
                    />
                  </div>
                </div>
              </div>

              <p className='text-brand-body mt-10 text-center text-sm'>
                <T keyName='eleo-dont-have-an-account'>Don't have an account?</T>
                <Link
                  to={props.branding ? `/${props.branding}/signup` : '/signup'}
                  className='text-brand-violet font-semibold leading-6 hover:text-indigo-500'
                >
                  {' '}
                  <T keyName='eleo-header-sign-up-button'>Sign Up</T>
                </Link>
              </p>
            </div>
          </>
        )}
      </div>
    </Animate>
  )
}
