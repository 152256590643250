import { useTranslate } from '@tolgee/react'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Microbutton, MuiTooltip } from 'ui'
import LibraryItemActions from './LibraryItemActions'
import { useCallback, useState } from 'react'
import FallbackImage from '../../../images/fallback-bot.png'
import classNames from 'classnames'
import MyChatBotBadge from '../myChatBotBadge'
import { useChatBotVisibilityOptions } from 'components/hooks/useChatBotVisibilityOptions'

export default function LibraryItem({
  item,
  handleLikeChatbot,
  handleRecommendChatbot,
  wrapperClassOverrides,
  boxContainerClassOverrides,
  descriptionBoxClassOverrides,
  imageClassOverrides,
  showChangeUserRoleModal,
  handleDeleteChatBot,
  disableActions = false,
  disableOverlayColors = false,
  secondary = false,
  tertiary = false,
  setCreateChatbotModalData,
}) {
  const [activePopoverId, setActivePopoverId] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const { visibilityOptionsEnum } = useChatBotVisibilityOptions()

  const handlePopoverOpen = (anchorElement, itemId) => {
    setAnchorEl(anchorElement)
    setActivePopoverId(itemId)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setActivePopoverId(null)
  }
  const navigate = useNavigate()
  const { t } = useTranslate()
  const isDraft = item?.type === 'draft'

  const renderBorderColor = useCallback(() => {
    if (!secondary || isDraft) return 'border border-brand-violet-semi_transparent/20'

    if (item?.settings?.publishing?.forum && item.visibilityOption === 'public')
      return 'border-2 border-[#3cd395]'
    if (item?.settings?.publishing?.forum && item.visibilityOption === 'private')
      return 'border-2 border-[#F38F2B]'
    if (item?.settings?.publishing?.forum && item.visibilityOption === 'groups')
      return 'border-2 border-[#2CADF2]'

    if (
      item?.visibilityOption === visibilityOptionsEnum.LINK ||
      item?.settings?.publishing?.public ||
      item?.settings?.publishing?.private
    )
      return 'border-2 border-brand-violet-secondary'

    return 'border border-brand-violet-semi_transparent/20'
  }, [visibilityOptionsEnum, item, secondary, isDraft])

  return (
    <div
      onClick={() =>
        tertiary
          ? setCreateChatbotModalData({ isVisible: true, chatbotId: null })
          : !secondary &&
            navigate(
              `/chatbot/chat/${item.customUrls?.forum?.enableCustomUrl ? item.customUrls?.forum?.customUrlName : item.id}`
            )
      }
      className={classNames(
        'group w-full max-w-[190px] rounded-[8px] transition-all  duration-300 ease-in-out hover:shadow-[0px_0px_10px_0px_rgba(0,0,0,0.25)]',
        (!secondary || tertiary) && 'cursor-pointer',
        renderBorderColor(),
        boxContainerClassOverrides
      )}
    >
      <div className={classNames('flex flex-col', wrapperClassOverrides)}>
        <div className='relative'>
          {secondary && (
            <MyChatBotBadge
              chatBotVisibilityOption={item?.visibilityOption}
              chatbotPublishing={item?.settings.publishing}
              isDraft={isDraft}
              id={item.id}
              item={item}
            />
          )}
          <img
            src={tertiary ? FallbackImage : item.avatar || FallbackImage}
            alt={tertiary ? 'Create new bot' : (item?.forum?.displayName ?? item.name)}
            className={classNames(
              'h-[190px] w-full rounded-b-[4px] rounded-t-[6px] object-cover',
              {
                'grayscale transition-all duration-300 group-hover:grayscale-0': tertiary,
                grayscale: isDraft,
              },
              imageClassOverrides
            )}
          />

          {!tertiary && !disableActions && (
            <div className='absolute bottom-[10px] left-[13px]'>
              <LibraryItemActions
                item={item}
                showChangeUserRoleModal={showChangeUserRoleModal}
                handleRecommendChatbot={handleRecommendChatbot}
                handleLikeChatbot={handleLikeChatbot}
                onPopoverOpen={handlePopoverOpen}
                onPopoverClose={handlePopoverClose}
                isPopoverOpen={activePopoverId === item.id}
                anchorEl={activePopoverId === item.id ? anchorEl : null}
                secondary={secondary}
                setCreateChatbotModalData={setCreateChatbotModalData}
                isDraft={isDraft}
                handleDeleteChatBot={handleDeleteChatBot}
              />
            </div>
          )}
        </div>
        {!tertiary ? (
          <div
            className={classNames(
              'cursor-pointer p-[16px] transition-all  duration-300 ',
              {
                'rounded-bl-[8px] rounded-br-[8px] bg-white/30 group-hover:bg-white':
                  !disableOverlayColors,
              },
              descriptionBoxClassOverrides
            )}
          >
            <h2 className='text-brand-body line-clamp-2 min-h-[30px] text-[14px] font-bold leading-[1.1em] tracking-normal'>
              {item.forum?.displayName?.length ? item.forum.displayName : item.name}
            </h2>
            {/* author info */}
            <div className='mb-[10px] mt-[4px] flex items-center gap-[3px]'>
              <p className='text-brand-body text-[12px]  leading-[1.3em] opacity-70'>
                {t('eleo-chat-forum-card-author-by', 'By')}
              </p>

              <span
                onClick={(e) => {
                  e.stopPropagation()
                  navigate(`/chatbot/library/profile/${item.author}`)
                }}
                className=' text-brand-violet-tertiary lg:text-brand-body group-hover:text-brand-violet-tertiary line-clamp-1 text-[12px] leading-[1.3em] underline opacity-70  transition-all duration-300  hover:!opacity-100 group-hover:underline  group-hover:opacity-75 lg:no-underline'
              >
                {item.author}
              </span>
            </div>
            {/* description */}
            <div className='min-h-[46px]'>
              <p className='text-brand-gray line-clamp-2 min-h-[32px] text-[12px] leading-[1.3em]'>
                {item.forum?.shortDescription || item.shortDescription}
              </p>
              {(item?.forum?.longDescription || item?.longDescription) && (
                <div className='cursor-pointer'>
                  <MuiTooltip
                    content={item.forum?.longDescription || item?.longDescription}
                    enterDelay={1500}
                    leaveDelay={500}
                    offset={[0, -14]}
                    className='max-w-[249px]'
                    borderColor='#bfbfe3'
                    shadow='none'
                    titleClassOverrides='!text-brand-violet-secondary  !font-light'
                    openOnClick
                    portalContainer={document.body}
                  >
                    <p className='text-brand-violet-tertiary lg:text-brand-gray group-hover:text-brand-violet-tertiary text-[12px] leading-[1.3em] underline  opacity-75 transition-all  duration-300 hover:!opacity-100 group-hover:underline group-hover:opacity-75 lg:no-underline lg:opacity-100'>
                      {t('eleo-chat-forum-card-read-more', 'Read more...')}
                    </p>
                  </MuiTooltip>
                </div>
              )}
            </div>
            {/* category */}
            <div className='mt-[10px]'>
              <Microbutton
                type='main'
                text={t(item.forum?.category || item.category)}
                onClick={(e) => {
                  e.stopPropagation()
                  navigate(`/chatbot/library?category=${item.forum?.category || item.category}`)
                }}
                className='!bg-brand-violet-semi_transparent !inline-block !max-h-min !w-auto !min-w-min !rounded-[4px] !border  !border-[#d9d9d9] !px-[6px]  !py-[4px] transition-all duration-300 lg:!bg-transparent '
                hoverClasses='hover:group-hover:!bg-brand-violet-semi_transparent/20 group-hover:!bg-brand-violet-semi_transparent'
                textClasses='!text-[12px] !font-normal !leading-[1.4em]  !text-brand-violet-secondary lg:!text-brand-gray opacity-70  group-hover:!text-brand-violet-secondary'
              />
            </div>
          </div>
        ) : (
          <div className='flex  cursor-pointer items-center justify-center rounded-bl-[8px] rounded-br-[8px] bg-white/30 p-[16px] py-[74px] transition-all  duration-300 group-hover:bg-white'>
            <Button className='bg-brand-violet-secondary hover:!bg-brand-violet-tertiary !h-[28px] !w-min !rounded-[4px] !px-[8px] !py-[4px] transition-all duration-300'>
              <p className='whitespace-nowrap text-[12px] font-medium uppercase leading-[1.2em] text-white'>
                {t('eleo-my-chatbots-new-chatbot', 'New chatbot')}
              </p>
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
