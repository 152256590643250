import { T } from '@tolgee/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'ui'
import Divider from './Divider'
import { Liked } from './sections/Liked'
import { Popular } from './sections/Popular'
import { RecentlyAdded } from './sections/RecentlyAdded'
import { RecentlyUsed } from './sections/RecentlyUsed'

export const ChatbotLibraryLayout = ({ filter, searchQuery, setFilter }) => {
  const [signal, refetchData] = useState(new Date())
  const navigate = useNavigate()

  return (
    <div className='flex h-full flex-col'>
      <RecentlyUsed filter={filter} signal={signal} refetchData={refetchData} />
      <Liked filter={filter} signal={signal} refetchData={refetchData} />
      <Popular filter={filter} signal={signal} refetchData={refetchData} />
      <RecentlyAdded filter={filter} signal={signal} refetchData={refetchData} />
      <div className='bg-[#f5f5fa] p-[14px] lg:p-[40px]'>
        <div className='chat-library-banner-bg-level-1 relative rounded-[20px] lg:h-[132px]'>
          <div className='chat-library-banner-bg-level-2'></div>
          <div className='chat-library-banner-bg-level-3'></div>
          <div className='chat-library-banner-bg-level-4'></div>
          <div className='relative z-[99] flex h-full w-full flex-wrap items-center justify-between px-[20px] lg:flex-nowrap lg:px-[60px]'>
            <div className='flex flex-col gap-[6px] py-[20px] lg:py-[36px]'>
              <p className='text-[15px]  font-medium leading-[1.2em] text-white'>
                <T
                  keyName='eleo-chatbot-cta'
                  params={{ br: <br></br> }}
                  defaultValue='Ready to dive in?'
                />
              </p>
              <p className='text-[30px] font-light leading-[1.2em] text-white'>
                <T keyName='eleo-chatbot-cta-subtitle'>Make your own chatbot today</T>
              </p>
            </div>

            <div className='flex  w-full  gap-2 pb-[20px] lg:max-w-[250px] lg:pb-0'>
              <Button
                className='!h-[50px] !w-full  !rounded-[50px] !bg-[#51c1f5] !p-[12px]'
                onClick={() => navigate('/chatbot/library/profile')}
              >
                <p className='!text-[15px] font-medium uppercase leading-[1.2em] tracking-[0.02em] text-white'>
                  <T keyName='eleo-start-today-action'>get started</T>
                </p>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-[#f5f5fa] pb-8' />
    </div>
  )
}
