/***
 *
 *   MESSAGE
 *   Colored feedback message with optional call to action
 *
 *   PROPS
 *   type - info/success/warning/error
 *   title - descriptive string
 *   text - string
 *   closable - boolean to determine if the user can close the message
 *   buttonText - text for the cta button (optional)
 *   buttonLink - url link for the button (optional)
 *
 **********/

import { useState } from 'react'
import { Button, Icon, useNavigate, ClassHelper } from 'components/lib'
import Style from './message.tailwind.js'
import classNames from 'classnames'

export function Message(props) {
  const navigate = useNavigate()

  // state
  const [closed, setClosed] = useState(false)
  const type = props.type || 'info'

  if (closed) return false

  const icon = {
    info: 'info',
    infoSecondary: 'info',
    success: 'check',
    warning: 'alert-triangle',
    error: 'alert-octagon',
  }

  const color = {
    info: 'blue',
    success: 'green',
    warning: 'orange',
    error: 'red',
    infoSecondary: 'purple',
  }

  // style
  const messageStyle = ClassHelper(Style, {
    message: true,
    [`${color[type]}Bg`]: true,
    className: props.className,
  })

  const titleStyle = ClassHelper(Style, {
    title: true,
    [`${color[type]}Text`]: true,
  })

  return (
    <div className={classNames(messageStyle, props.isFlex && 'lg:flex lg:items-center')}>
      {props.icon ?? (
        <Icon className={Style.icon} size={30} color={color[type]} image={icon[type]} />
      )}

      {props.closable && (
        <Button
          icon='x'
          position='absolute'
          className={Style.close}
          action={(e) => setClosed(true)}
        />
      )}

      <section className={classNames(Style.content, props.messageSectionClasses)}>
        {props.title && (
          <h1 className={classNames(titleStyle, props.sectionTitleClasses)}>{props.title}</h1>
        )}

        {props.text && <p>{props.text}</p>}

        {props.children && props.children}
      </section>

      {(props.buttonLink || props.buttonAction) && (
        <Button
          className={Style.btn}
          color={color[type]}
          text={props.buttonText}
          action={(e) => {
            props.buttonLink ? navigate(props.buttonLink) : props.buttonAction?.()
          }}
        />
      )}

      {props.secondaryIcon && props.secondaryIcon}
    </div>
  )
}
