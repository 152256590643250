import { ChatBubble } from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'
import axios from 'axios'
import { ViewContext } from 'components/lib'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ChatbotsCarousel } from '../ChatbotsCarousel'
import SectionSkeletonLoader from './SectionSkeletonLoader'
import Divider from '../Divider'

export const RecentlyUsed = ({ filter, signal, refetchData, clearSearch, searchHintsUI }) => {
  const context = useContext(ViewContext)
  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslate()
  const params = useSearchParams()[0]

  const fetchItems = useCallback(async () => {
    try {
      setIsLoading(true)
      const category = params.get('category')
      const res = await axios.get(
        `/api/chat-bot/library/recent?page=1&pageSize=200&filter=${filter}${category ? `&category=${category}` : ''}`
      )
      setItems(res.data)
    } catch (err) {
      context.handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [filter, params])

  useEffect(() => {
    fetchItems()
  }, [fetchItems, signal])

  if (isLoading && !items.length) {
    return <SectionSkeletonLoader />
  }

  if (!items.length) return

  const title = t('recently-used-chatbots-title', 'KEEP TALKING TO')

  return (
    <div className='bg-[#f5f5fa]'>
      <ChatbotsCarousel
        items={items}
        title={title}
        icon={<ChatBubble sx={{ height: 18, width: 18 }} />}
        refetchData={refetchData}
        seeMoreUrl='/chatbot/library/recent'
      />
      <Divider />
    </div>
  )
}

export default RecentlyUsed
