import { Search, ThumbUp } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'
import { useWindowDimensions } from 'components/hooks/window'
import { Animate, useAPI, ViewContext } from 'components/lib'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import CategoriesSwitch from 'toolComponents/chat-bot/library/CategoriesSwitch'
import { ChatbotsCarousel } from 'toolComponents/chat-bot/library/ChatbotsCarousel'
import Divider from 'toolComponents/chat-bot/library/Divider'
import SearchHintsUI from 'toolComponents/chat-bot/library/SearchHintsUI'
import Liked from 'toolComponents/chat-bot/library/sections/Liked'
import MyChatBotList from 'toolComponents/chat-bot/myChatBotList'
import { Button, Input } from 'ui'
import { useDebounce } from 'use-debounce'
import ChatbotCreation from '../account/chatBot'
import DuplicateModalView from 'toolComponents/chat-bot/library/DuplicateModalView'
import { errorToast, successToast } from 'helpers'
import ActionModal from 'toolComponents/writer/ActionModal'
import { ChatBotAPIService } from 'service/chat-bot-rest-api.service'
import { useDispatch } from 'react-redux'
import { getChatBotList } from 'store/account/chatBot'
import classNames from 'classnames'

export const ChatbotLibraryMyChatBots = () => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const chatbotCreationModalRef = useRef()
  const context = useContext(ViewContext)
  const [myChatBots, setMyChatBots] = useState([])
  const [chatBotToDelete, setChatBotToDelete] = useState({ chatBotId: '', type: '' })
  const [recommendations, setRecommendations] = useState([])
  const [authorData, setAuthorData] = useState({ description: '' })
  const [filter, setFilter] = useState('')
  const [debounceFilter] = useDebounce(filter, 1000)
  const [signal, refetchData] = useState(new Date())
  const [params, setParams] = useSearchParams()
  const authorParam = useParams()
  const selectedCategory = params.get('category') || 'eleo-chat-library-all-categories'
  const { width } = useWindowDimensions()
  const [author, setAuthor] = useState('')
  const [modalIsVisible, setModalIsVisible] = useState({
    content: '',
    visible: false,
  })

  const category = params.get('category')

  const handleDeleteChatBot = async (chatBotId, type) => {
    setChatBotToDelete({ chatBotId, type })
    setModalIsVisible({ content: 'DELETE_TEMPLATE', visible: true })
  }

  const [createChatbotModalData, setCreateChatbotModalData] = useState({
    isVisible: Boolean(params.get('create')),
    chatbotId: null,
  })

  const accountData = useAPI('/api/user/account', 'GET', !Boolean(authorParam?.author))

  useEffect(() => {
    const requestedChatbot = params.get('edit')
    if (requestedChatbot) {
      setCreateChatbotModalData({ isVisible: true, chatbotId: requestedChatbot })
    } else {
      setCreateChatbotModalData({ isVisible: false, chatbotId: null })
    }
  }, [params])

  useEffect(() => {
    if (!authorParam.author) {
      setAuthor(accountData.data?.[0].name)
    } else {
      setAuthor(authorParam.author)
    }
  }, [authorParam, accountData])

  const fetchMyChatbotList = useCallback(async () => {
    try {
      const res = await axios.get(
        `/api/chat-bot/list?filter=${debounceFilter}${category ? `&category=${category}` : ''}`
      )
      setMyChatBots(res.data)
    } catch (err) {
      context.handleError(err)
    }
  }, [debounceFilter, category])

  const handleDuplicateChatBot = async (originalChatBotId, duplicatedName) => {
    try {
      await axios.post('/api/chat-bot/draft/duplicate', {
        originalChatBotId,
        name: duplicatedName,
      })
      successToast(t('eleo-duplicate-bot-success', 'Chatbot duplicated and saved to drafts'))
      dispatch(getChatBotList())
      fetchMyChatbotList()
      context.modal.hide()
    } catch (err) {
      if (err.response.data.error.code === 'DUPLICATE_NAME') {
        errorToast(t('eleo-duplicated-name-error', 'Duplicated name, name must be unique!'))
        context.modal.hide()
      } else {
        errorToast(t('eleo-generic-error'))
      }
    }
  }

  const deleteDraft = async () => {
    return ChatBotAPIService.generateRequest(
      { draftId: chatBotToDelete.chatBotId },
      '/chat-bot/draft/delete'
    )
  }

  function showChangeUserRoleModal(originalChatBotId) {
    context.modal.show({
      children: (
        <div>
          <DuplicateModalView
            originalChatBotId={originalChatBotId}
            handleDuplicateChatBot={handleDuplicateChatBot}
          />
        </div>
      ),
    })
  }

  const fetchRecommendations = useCallback(async () => {
    try {
      const res = await axios.get(
        `/api/chat-bot/library/author/recommendations/${author}?filter=${debounceFilter}&author=${author}${category ? `&category=${category}` : ''}`
      )
      setRecommendations(res.data.data.chatbots)
      setAuthorData({ description: res.data.data.description })
    } catch (err) {
      context.handleError(err)
    }
  }, [debounceFilter, category, author])

  const fetchAll = useCallback(() => {
    fetchRecommendations()
    fetchMyChatbotList()
  }, [fetchRecommendations, fetchMyChatbotList])

  const clearSearch = () => {
    setFilter('')
  }

  useEffect(() => {
    if (author) {
      fetchAll()
    }
  }, [fetchAll, author])

  return (
    <>
      <Animate classOverrides='bg-[#f5f5fa] !h-full'>
        <div className='flex h-full flex-col overflow-y-scroll bg-[#f5f5fa] pb-[160px] lg:pb-0'>
          <CategoriesSwitch
            filter={filter}
            categoryClassNamesOverrides='!top-[0px]'
            setFilter={setFilter}
          />
          <div className=' h-fit'>
            <MyChatBotList
              isSearchActive={filter.length}
              searchHintsUI={<SearchHintsUI searchKey={filter} category={selectedCategory} />}
              myChatBots={myChatBots}
              clearSearch={clearSearch}
              setCreateChatbotModalData={setCreateChatbotModalData}
              showChangeUserRoleModal={showChangeUserRoleModal}
              handleDeleteChatBot={handleDeleteChatBot}
            />
          </div>
          <Divider />
          <div className='h-fit'>
            <ChatbotsCarousel
              disableSlider={width > 1024}
              isSearchActive={filter.length}
              clearSearch={clearSearch}
              searchHintsUI={<SearchHintsUI searchKey={filter} category={selectedCategory} />}
              items={recommendations}
              icon={
                filter.length ? (
                  <Search sx={{ height: '18px', width: '18px' }} />
                ) : (
                  <ThumbUp sx={{ height: '18px', width: '18px' }} />
                )
              }
              title={<T keyName='eleo-recommended-by-me-title' defaultValue='Recommended by me' />}
              refetchData={() => {
                fetchAll()
                refetchData(Date.now())
              }}
            />
          </div>
          <Divider />

          <div className='h-fit'>
            <Liked
              filter={debounceFilter}
              isSearchActive={filter.length}
              clearSearch={clearSearch}
              searchHintsUI={<SearchHintsUI searchKey={filter} category={selectedCategory} />}
              disableSlider={width > 1024}
              returnHeader
              signal={signal}
              customTitle={<T keyName='eleo-favorites-by-me-title' defaultValue='My favorites' />}
              refetchData={() => {
                fetchAll()
                refetchData(Date.now())
              }}
            />
          </div>
          {/* cta */}
          <div className='fixed bottom-[25px] right-[20px] z-[99] flex gap-[10px]'>
            <Link to={`/chatbot/library/profile/${author}`}>
              <Button className='bg-brand-violet-secondary hover:!bg-brand-violet-tertiary !h-[40px] !w-min !rounded-[4px] !p-[12px] transition-all duration-300'>
                <p className='whitespace-nowrap px-[3px] text-[12px] font-medium uppercase leading-[1.2em] text-white'>
                  <T keyName='eleo-my-chatbots-view-profile'>View my profile</T>
                </p>
              </Button>
            </Link>
            <div
              onClick={() => setCreateChatbotModalData((prev) => ({ ...prev, isVisible: true }))}
            >
              <Button className='bg-brand-violet-secondary hover:!bg-brand-violet-tertiary !h-[40px] !w-min !rounded-[4px] !p-[12px] transition-all duration-300'>
                <p className='whitespace-nowrap px-[3px] text-[12px] font-medium uppercase leading-[1.2em] text-white'>
                  <T keyName='eleo-my-chatbots-new-chatbot'>New chatbot</T>
                </p>
              </Button>
            </div>
          </div>
        </div>
        {createChatbotModalData.isVisible && (
          <div
            className={classNames(
              'bg-brand-violet/80 fixed inset-0 z-[1000] lg:px-10 lg:py-8',
              params.get('publishing') && 'invisible'
            )}
            onClick={(e) =>
              e.target === e.currentTarget &&
              // setCreateChatbotModalData(() => ({ isVisible: false, chatbotId: null }))
              chatbotCreationModalRef.current.handleCloseModal()
            }
          >
            <div className='h-full overflow-clip rounded bg-white shadow-[0_0_20px_#00000040]'>
              <ChatbotCreation
                ref={chatbotCreationModalRef}
                handleHideModal={() => {
                  setCreateChatbotModalData(() => ({ isVisible: false, chatbotId: null }))
                  setParams()
                }}
                chatbotId={createChatbotModalData.chatbotId}
                onCreateCallback={fetchMyChatbotList}
              />
            </div>
          </div>
        )}

        {modalIsVisible.visible && (
          <ActionModal
            modalKey={modalIsVisible.content}
            setModalIsVisible={setModalIsVisible}
            reloadTemplatesData={() => {
              fetchMyChatbotList()
              setChatBotToDelete({ type: '', chatBotId: '' })
            }}
            modalHeaderText={<T keyName='eleo-delete-bot-modal-title'>Delete Chat Bot</T>}
            deleteTemplateEndpoint={() =>
              chatBotToDelete.type === 'draft'
                ? deleteDraft()
                : ChatBotAPIService.deleteChatBot({ chatBotId: chatBotToDelete.chatBotId })
            }
            mainTitle={
              <T keyName='eleo-delete-bot-header'>Are you sure you want to delete this Chat Bot?</T>
            }
          />
        )}
      </Animate>
    </>
  )
}

export default ChatbotLibraryMyChatBots
