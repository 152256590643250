/***
 *
 *   SIGN UP STEP 1
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: verify email address
 *   Step 3: select plan
 *
 **********/

import { Facebook, Google, Twitter, X } from '@mui/icons-material'
import { T, useTranslate, useTolgee } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { Animate, AuthContext, ViewContext } from 'components/lib'
import { errorToast, getLandingUrl } from 'helpers'
import parse from 'html-react-parser'
import { CircleNotch } from 'phosphor-react'
import { useContext, useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import settings from 'settings'
import { errorCodes } from 'components/view/errorCodes'
import { Button, CheckBox, Input } from 'ui'

const socialThemes = {
  google: 'bg-[#db4437] hover:bg-[#db4537c1]',
  facebook: 'bg-[#3b5998] hover:bg-[#3b5998c1]',
  x: 'bg-black hover:bg-black/90',
}

export function Signup(props) {
  const authContext = useContext(AuthContext)
  const context = useContext(ViewContext)
  const [params, setParams] = useSearchParams()
  const { t } = useTranslate()
  const tolgee = useTolgee()

  const next = params.get('next')
  const inviteId = params.get('id')
  const socialProvider = params.get('social')

  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState(params.get('email') ?? '')
  const [password, setPassword] = useState('')
  const [isTermsAccepted, setIsTermsAccepted] = useState(false)
  const [isNewsletterAccepted, setIsNewsletterAccepted] = useState(false)
  const [invalidFields, setInvalidFields] = useState({
    name: false,
    email: false,
    password: false,
    terms: false,
  })

  useEffect(() => {
    const error = params.get('error')
    if (error) {
      if (errorCodes[error]) errorToast(errorCodes[error], null, 10000)
      else errorToast(error, null, 10000)

      params.delete('error')
      setParams(params)
    }
  }, [params, setParams, t])

  useEffect(() => {
    setIsTermsAccepted(false)
    setIsNewsletterAccepted(false)
    setInvalidFields({
      name: false,
      email: false,
      password: false,
      terms: false,
      invalidPasswordLength: false,
    })
  }, [socialProvider])

  async function socialSingup() {
    if (!isTermsAccepted) {
      setInvalidFields((prev) => ({ ...prev, terms: true }))
      return false
    }

    const currentProcess = process.env.REACT_APP_MODE
      ? process.env.REACT_APP_MODE
      : process.env.NODE_ENV
    const serverURL = settings[currentProcess].server_url

    let qs = inviteId ? `?invite=${inviteId}&signin_url=${window.location.href}` : '?signup=1'
    if (next) qs += `&next=${next}`
    if (isNewsletterAccepted) qs += '&newsletter=1'
    if (isTermsAccepted) qs += '&terms=1'
    if (props.branding) qs += `&referrer=${props.branding}`
    qs += `&language=${tolgee.getPendingLanguage()}`

    window.location = `${serverURL}/auth/${socialProvider}${qs}`
    try {
    } catch (err) {
      console.log(err)
    }
  }

  async function handleSubmit() {
    // Field validation
    const emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    const invalid = {
      name: false,
      email: false,
      password: false,
      terms: false,
      invalidPasswordLength: false,
    }
    if (!name) invalid.name = true
    if (!email || !emailReg.test(email)) invalid.email = true
    if (!password) invalid.password = true
    if (password && password.length < 5) invalid.invalidPasswordLength = true
    if (!isTermsAccepted) invalid.terms = true

    if (!name || !email || !password || !isTermsAccepted || password.length < 5) {
      setInvalidFields(invalid)
      return false
    }

    setIsLoading(true)
    try {
      const data = {
        name: name,
        email: email,
        password: password,
        terms: isTermsAccepted,
        newsletter: isNewsletterAccepted,
        language: tolgee.getPendingLanguage(),
        referrer: props.branding,
        ...(inviteId && { invite_id: inviteId }),
      }
      const url = inviteId ? '/api/user' : '/api/account'
      const res = await axios.post(url, data)

      authContext.signin(res, next)
    } catch (err) {
      context.handleError(err)
      setIsLoading(false)
    }
  }

  return (
    <Animate type='pop'>
      <div className='flex justify-center border-y py-10'>
        <div className='w-[min(460px,95%)] space-y-[20px] sm:w-[493px] sm:space-y-[30px]'>
          {/* Header */}
          <div className='text-center text-[26px] font-black leading-[1.2em]'>
            {t('eleo-signup-header', 'Create an account')}
          </div>

          {/* Container */}
          <div className='rounded-[20px] sm:p-[10px] sm:shadow-[0px_0px_10px_0px_rgba(0,0,0,0.25)]'>
            <div className='rounded-[10px] bg-white p-[16px] shadow-[0px_0px_10px_0px_rgba(0,0,0,0.25)] sm:p-[30px]'>
              {socialProvider ? (
                <>
                  {/* Checkboxes */}
                  <div className='space-y-[10px]'>
                    <div className='rounded-[10px] bg-[#FB427C]'>
                      <div
                        className={classNames(
                          'flex items-center gap-x-[8px] rounded-lg bg-white px-[6px] py-[10px]',
                          invalidFields.terms && 'border border-[#FB427C]'
                        )}
                      >
                        <CheckBox
                          id='termsCheckbox'
                          checkBoxValue={isTermsAccepted}
                          setChecked={({ checked }) => {
                            setIsTermsAccepted(checked)
                            setInvalidFields((prev) => ({ ...prev, terms: false }))
                          }}
                        />
                        <label
                          htmlFor='termsCheckbox'
                          className='cursor-pointer text-balance text-[14px] leading-[1.1em]'
                        >
                          {parse(
                            t('eleo-plan-i-accept-terms-and-conditions', {
                              terms: getLandingUrl('/terms'),
                              privacy: getLandingUrl('/privacy'),
                            })
                          )}
                          <span className='text-brand-violet'> *</span>
                        </label>
                      </div>
                      {invalidFields.terms && (
                        <div className='px-[10px] py-[6px] text-[14px] leading-[1.1em] text-white'>
                          {t(
                            'eleo-signup-error-terms',
                            'Please accept the Terms and Conditions and Privacy Policy'
                          )}
                        </div>
                      )}
                    </div>
                    <div className='flex items-center gap-x-[8px] px-[6px]'>
                      <CheckBox
                        id='newsletterCheckbox'
                        checkBoxValue={isNewsletterAccepted}
                        setChecked={({ checked }) => setIsNewsletterAccepted(checked)}
                      />
                      <label
                        htmlFor='newsletterCheckbox'
                        className='cursor-pointer text-balance text-[14px] leading-[1.1em]'
                      >
                        {t(
                          'eleo-signup-label-newsletter',
                          "Send me information about what's new in the application"
                        )}
                      </label>
                    </div>
                  </div>

                  {/* Social signin button */}
                  <Button
                    className={classNames(
                      'mt-[24px] !h-[48px] w-full gap-x-[10px] !text-[15px] uppercase leading-[1.2em]',
                      socialThemes[socialProvider]
                    )}
                    onClick={socialSingup}
                  >
                    {t('eleo-signup-social-submit', 'Create account with') + ' ' + socialProvider}
                  </Button>

                  {/* Go back button */}
                  <div className='text-brand-body -mb-2 mt-5 text-center font-medium leading-6 opacity-80 sm:-mb-6'>
                    <span
                      className='cursor-pointer p-2'
                      onClick={() =>
                        setParams((prev) => {
                          prev.delete('social')
                          return prev
                        })
                      }
                    >
                      {t('eleo-signup-social-go-back', 'Go back')}
                    </span>
                  </div>
                </>
              ) : (
                <div className='space-y-[10px] sm:space-y-[20px]'>
                  {/* Inputs */}
                  <div>
                    <div className='space-y-[6px]'>
                      <label
                        htmlFor='name'
                        className='px-[6px] text-[13px] font-medium leading-[1.2em] '
                      >
                        {t('eleo-signup-label-name', 'Name')}
                        <span className='text-brand-violet'> *</span>
                      </label>
                      <div className='rounded-[10px] bg-[#FB427C]'>
                        <Input
                          value={name}
                          onChange={setName}
                          id='name'
                          name='name'
                          className={classNames(
                            'h-[38px] w-full bg-white !p-[10px]',
                            invalidFields.name && '!border-[#FB427C]'
                          )}
                          placeholder=''
                          onFocus={() => setInvalidFields((prev) => ({ ...prev, name: false }))}
                        />
                        {invalidFields.name && (
                          <div className='px-[10px] py-[6px] text-[14px] leading-[1.1em] text-white'>
                            {t('eleo-signup-error-name', 'Please enter your name')}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='space-y-[6px]'>
                      <label
                        htmlFor='email'
                        className='px-[6px] text-[13px] font-medium leading-[1.2em]'
                      >
                        {t('eleo-signup-label-email', 'Email')}
                        <span className='text-brand-violet'> *</span>
                      </label>
                      <div className='rounded-[10px] bg-[#FB427C]'>
                        <Input
                          value={email}
                          onChange={setEmail}
                          type='email'
                          id='email'
                          name='email'
                          className={classNames(
                            'h-[38px] w-full bg-white !p-[10px]',
                            invalidFields.email && '!border-[#FB427C]'
                          )}
                          placeholder=''
                          onFocus={() => setInvalidFields((prev) => ({ ...prev, email: false }))}
                        />
                        {invalidFields.email && (
                          <div className='px-[10px] py-[6px] text-[14px] leading-[1.1em] text-white'>
                            {t('eleo-signup-error-email', 'Please enter a valid email')}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='space-y-[6px]'>
                      <label
                        htmlFor='password'
                        className='px-[6px] text-[13px] font-medium leading-[1.2em] '
                      >
                        {t('eleo-signup-label-password', 'Password')}
                        <span className='text-brand-violet'> *</span>
                      </label>
                      <div className='rounded-[10px] bg-[#FB427C]'>
                        <Input
                          type='password'
                          value={password}
                          onChange={setPassword}
                          id='password'
                          className={classNames(
                            'h-[38px] w-full bg-white !p-[10px]',
                            invalidFields.password && '!border-[#FB427C]'
                          )}
                          placeholder=''
                          onFocus={() =>
                            setInvalidFields((prev) => ({
                              ...prev,
                              password: false,
                              invalidPasswordLength: false,
                            }))
                          }
                          onKeyDown={(e) => e.code === 'Enter' && handleSubmit()}
                        />
                        {invalidFields.password && (
                          <div className='px-[10px] py-[6px] text-[14px] leading-[1.1em] text-white'>
                            {t('eleo-signup-error-password', 'Please enter your password')}
                          </div>
                        )}
                        {invalidFields.invalidPasswordLength && (
                          <div className='px-[10px] py-[6px] text-[14px] leading-[1.1em] text-white'>
                            {t(
                              'eleo-password-validation-min-length-error',
                              'Password must be a minimum of 5 characters'
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Checkboxes */}
                  <div className='space-y-[10px]'>
                    <div className='rounded-[10px] bg-[#FB427C]'>
                      <div
                        className={classNames(
                          'flex items-center gap-x-[8px] rounded-lg bg-white px-[6px] py-[10px]',
                          invalidFields.terms && 'border border-[#FB427C]'
                        )}
                      >
                        <CheckBox
                          id='termsCheckbox'
                          checkBoxValue={isTermsAccepted}
                          setChecked={({ checked }) => {
                            setIsTermsAccepted(checked)
                            setInvalidFields((prev) => ({ ...prev, terms: false }))
                          }}
                        />
                        <label
                          htmlFor='termsCheckbox'
                          className='cursor-pointer text-balance text-[14px] leading-[1.1em]'
                        >
                          {parse(
                            t('eleo-plan-i-accept-terms-and-conditions', {
                              terms: getLandingUrl('/terms'),
                              privacy: getLandingUrl('/privacy'),
                            })
                          )}
                          <span className='text-brand-violet'> *</span>
                        </label>
                      </div>
                      {invalidFields.terms && (
                        <div className='px-[10px] py-[6px] text-[14px] leading-[1.1em] text-white'>
                          {t(
                            'eleo-signup-error-terms',
                            'Please accept the Terms and Conditions and Privacy Policy'
                          )}
                        </div>
                      )}
                    </div>
                    <div className='flex items-center gap-x-[8px] text-balance px-[6px]'>
                      <CheckBox
                        id='newsletterCheckbox'
                        checkBoxValue={isNewsletterAccepted}
                        setChecked={({ checked }) => setIsNewsletterAccepted(checked)}
                      />
                      <label
                        htmlFor='newsletterCheckbox'
                        className='cursor-pointer text-[14px] leading-[1.1em]'
                      >
                        {t(
                          'eleo-signup-label-newsletter',
                          "Send me information about what's new in the application"
                        )}
                      </label>
                    </div>
                  </div>

                  {/* Create account */}
                  <Button
                    color='violet'
                    className='!mt-[24px] !h-[48px] w-full !text-[15px] uppercase leading-[1.2em]'
                    onClick={handleSubmit}
                  >
                    {isLoading ? (
                      <CircleNotch
                        weight='fill'
                        color='#FFFFFF'
                        size={24}
                        className='animate-spin'
                      />
                    ) : (
                      t('eleo-signup-label-submit', 'Create account')
                    )}
                  </Button>

                  {/* Spacer */}
                  <div className='relative !mt-[32px] flex justify-center'>
                    <span className='text-brand-body z-10 bg-white px-[10px] text-center text-[13px] text-opacity-70'>
                      {t('eleo-signup-spacer', 'Or continue with')}
                    </span>
                    <div className='absolute top-1/2 w-full border-b border-[rgb(230,230,230)]'></div>
                  </div>

                  {/* Social */}
                  <div className='!mt-[18px] flex flex-wrap gap-[10px]'>
                    <Button
                      className='!h-[48px] w-full gap-x-[10px] bg-[#3b5998] !text-[15px] uppercase leading-[1.2em] hover:bg-[#3b5998c1]'
                      style={{ width: 'calc(55% - 10px)' }}
                      onClick={() =>
                        setParams((prev) => {
                          prev.append('social', 'facebook')
                          return prev
                        })
                      }
                    >
                      <Facebook fontSize='small' />
                      Facebook
                    </Button>
                    <Button
                      className='!h-[48px] w-[45%] gap-x-[10px] bg-black !text-[15px] uppercase leading-[1.2em] hover:bg-black/80'
                      onClick={() =>
                        setParams((prev) => {
                          prev.append('social', 'x')
                          return prev
                        })
                      }
                    >
                      <X fontSize='small' />
                      X.com
                    </Button>
                    <Button
                      className='!h-[48px] w-full gap-x-[10px] bg-[#db4437] !text-[15px] uppercase leading-[1.2em] hover:bg-[#db4537c1]'
                      onClick={() =>
                        setParams((prev) => {
                          prev.append('social', 'google')
                          return prev
                        })
                      }
                    >
                      <Google fontSize='small' />
                      Google
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Footer section */}
          <p className='text-brand-body !mt-[20px] text-center text-sm'>
            <T keyName='eleo-already-registered'>Already registered?</T>{' '}
            <Link
              to={props.branding ? `/${props.branding}/signin` : '/signin'}
              className='text-brand-violet font-semibold leading-6 hover:text-indigo-500'
            >
              <T keyName='eleo-header-log-in-button'>Log In</T>
            </Link>
          </p>
        </div>
      </div>
    </Animate>
  )
}
