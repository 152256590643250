import { ToolGeneratorLayoutPrimary } from 'components/lib'
import React from 'react'
import ChatActions from './chatActions'
import ChatOptions from './chatOptions'

const ChatGenerator = ({
  isShowAdvanced,
  setIsShowAdvanced,
  isShowPublishing,
  setIsShowPublishing,
  handleGenerateChatBot,
  handleFileUpload,
  documentContext,
  publishState,
  setPublishState,
  chatbotServerState,
  isEditMode,
  loadingState,
  handleResetToServerState,
  isUpdatePending,
  isDraftSaved,
  chatbotDraftServerState,
  setModalData,
}) => {
  return (
    <ToolGeneratorLayoutPrimary
      options={
        <ChatOptions
          isShowAdvanced={isShowAdvanced}
          setIsShowAdvanced={setIsShowAdvanced}
          isShowPublishing={isShowPublishing}
          setIsShowPublishing={setIsShowPublishing}
          handleFileUpload={handleFileUpload}
          documentContext={documentContext}
          publishState={publishState}
          setPublishState={setPublishState}
          chatbotServerState={chatbotServerState}
          chatbotDraftServerState={chatbotDraftServerState}
          setModalData={setModalData}
          isUpdatePending={isUpdatePending}
          isDraftSaved={isDraftSaved}
          handleGenerateChatBot={handleGenerateChatBot}
        />
      }
      actions={
        <ChatActions
          handleGenerateChatBot={handleGenerateChatBot}
          isEditMode={isEditMode}
          loadingState={loadingState}
          handleResetToServerState={handleResetToServerState}
          isUpdatePending={isUpdatePending}
          isDraftSaved={isDraftSaved}
        />
      }
    />
  )
}

export default ChatGenerator
