import { useTranslate } from '@tolgee/react'
import axios from 'axios'
import { ViewContext } from 'components/lib'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ChatbotsCarousel } from '../ChatbotsCarousel'
import { useSearchParams } from 'react-router-dom'
import { Search, Verified } from '@mui/icons-material'
import SectionSkeletonLoader from './SectionSkeletonLoader'
import Divider from '../Divider'

export const Popular = ({ filter, signal, refetchData, clearSearch, searchHintsUI }) => {
  const context = useContext(ViewContext)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const { t } = useTranslate()
  const params = useSearchParams()[0]

  const fetchItems = useCallback(async () => {
    setLoading(true)
    try {
      const category = params.get('category')
      const res = await axios.get(
        `/api/chat-bot/library/popular?page=1&pageSize=200&filter=${filter}&period=ever${category ? `&category=${category}` : ''}`
      )
      setItems(res.data)
    } catch (err) {
      context.handleError(err)
    } finally {
      setLoading(false)
    }
  }, [filter, params])

  useEffect(() => {
    fetchItems()
  }, [fetchItems, signal])

  if (loading && !items.length) return <SectionSkeletonLoader />
  if (!items.length) return

  const title = t('popular-chatbots-title', 'MOST POPULAR')

  return (
    <div className='bg-[#f5f5fa]'>
      <ChatbotsCarousel
        items={items}
        title={title}
        icon={<Verified sx={{ height: 18, width: 18 }} />}
        refetchData={refetchData}
        seeMoreUrl='/chatbot/library/popular'
      />
      <Divider />
    </div>
  )
}

export default Popular
